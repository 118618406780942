import * as React from "react";
import {
  AppBar,
  Box,
  Button,
  ButtonProps,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import Logo from "../assets/logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, Link, NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { purple } from "@mui/material/colors";
import navItems from "../utils/navItems";
import MobileNavItems from "./MobileNavItem";
const drawerWidth = 240;

interface Props {
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const NavItem = ({ item }: any) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (item.childrens) {
    return (
      <>
        <Box
          sx={{
            position: "relative",
            "&:hover #nav-item-menu": { display: "block" },
          }}
        >
          <Typography
            variant="body1"
            id="nav-item-menu-item"
            // aria-controls={open ? "nav-item-menu-item" : undefined}
            // aria-haspopup="true"
            // aria-expanded={open ? "true" : undefined}
            // onClick={handleClick}
            // onMouseOver={handleClick}
            // onMouseLeave={() => setAnchorEl(null)}
            sx={{
              color: "#2D2D2D",
              // textTransform: "none",
              // fontWeight: 500,
              fontSize: "1rem",
              cursor: "pointer",
              padding: "0 0.7rem",
            }}
          >
            {item.name}
          </Typography>
          <Box
            component={Paper}
            display={"none"}
            id="nav-item-menu"
            sx={{
              position: "absolute",
              top: 25,
              left: 0,
              // backgroundColor: "#FFF6D4",
              borderRadius: "10px",
              minWidth: "210px",
              // padding: "2px 0",
              zIndex: 1000,
              transition: "all 0.5s ease-in-out",
              "#menu-item": { padding: "7px 10px" },
            }}
          >
            <Box display="flex" flexDirection="column">
              {item.childrens.map((child: any, index: number) => (
                <NavItem key={index} item={child} />
              ))}
            </Box>
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Typography
          component={Link}
          id="menu-item"
          to={item.path ? `/${item.path}` : ""}
          key={item.name}
          sx={{
            color: "#2D2D2D",
            textDecoration: "none",
            fontSize: "1rem",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#FFF6D4",
              borderRadius: "5px",
            },
          }}
        >
          {/* {item.name.toLowerCase() === "calls" && <Typography>a</Typography>} */}
          {item.name}
        </Typography>
      </>
    );
  }
};

function Navbar() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  // const { currentPage, setPage } = usePage();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // const handleClick = (item: any) => {
  //   if (item.path) {
  //     navigate(`/${item.path}`);
  //   } else {
  //     return;
  //   }
  // };

  const drawer = (
    <Box>
      <Link to="/">
        <img src={Logo} alt="" style={{ width: "60%", paddingBlock: 10 }} />
      </Link>
      <Divider />
      <List>
        {navItems.map((item: any, index: number) => (
          <MobileNavItems item={item} key={index} />
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {/* <CssBaseline /> */}
      <Container maxWidth="lg">
        <ElevationScroll>
          <AppBar
            component="nav"
            // color="transparent"
            position="fixed"
            sx={{
              backgroundColor: "#FFFDF7",
              borderBottom: "1px solid #FEEBA0",
            }}
          >
            {/* large screen */}

            <Toolbar sx={{ padding: 0 }}>
              <Container maxWidth="lg">
                <Box display="flex" alignItems="center">
                  <Box
                    sx={{
                      display: { xs: "none", sm: "block" },
                      flexGrow: 1,
                    }}
                  >
                    <Link to="/">
                      <img
                        src={Logo}
                        alt="Hubiquitous"
                        style={{ width: "186px" }}
                      />
                    </Link>
                  </Box>

                  <Box
                    sx={{
                      display: { xs: "none", sm: "flex" },
                      // flexGrow: 1,
                      // justifyContent: "center",
                    }}
                  >
                    {navItems.map((item, index) => (
                      <NavItem key={index} item={item} />
                    ))}
                  </Box>

                  {/* <Button
                    component={Link}
                    to={"/hubiquitous-programs"}
                    variant="contained"
                    sx={{
                      display: { xs: "none", sm: "block" },
                      color: "white",
                    }}
                  >
                    Discover the project
                  </Button> */}
                  {/* <Box display={{ sm: "none" }} flexGrow={1} /> */}
                  <IconButton
                    // color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{
                      // mr: 2,
                      display: { sm: "none" },
                      color: "black",
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              </Container>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </Container>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}

export default Navbar;
