import { useState } from 'react'; // Import useState from React
import useSWR, { SWRConfiguration } from 'swr'

const fetcher = (args: any) => fetch(args).then(res => res.json())

function useFetch(url: string, pageSize: number = 24,currentPage: number = 1, swrConfig?: SWRConfiguration) {
  const [,setCurrentPage] = useState(1);

  const { data, error, isLoading, mutate } = useSWR(
    `https://hubiquitous-cms-3bwjwnm3fq-uc.a.run.app/api${url}&pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}&sort[0]=publishedAt:desc`,
    fetcher,
    swrConfig
  );

  const loadMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const hasMore = data?.meta?.pagination?.pageCount > currentPage;

  return {
    data,
    isLoading,
    isError: error,
    loadMore,
    hasMore,
    mutate, // Expose mutate for manual data updates if needed
  };
}

export default useFetch;
