// Suggested code may be subject to a license. Learn more: ~LicenseLog:2044006210.
import React from 'react';
import { Link } from 'react-router-dom';
import {
 Typography, Box, Button, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const NotFound: React.FC = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="md" sx={{ marginTop: theme.spacing(8) }}>
      <Box textAlign="center">
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
          404 - Page Not Found
        </Typography>
        <Typography variant="body1" paragraph>
          The page you are
 looking for might have been removed, had its name changed, or is temporarily unavailable.
        </Typography>
        <Button component={Link} to="/" variant="contained" color="primary">
          Go to Homepage
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
