import React, { useEffect, useRef } from "react";
import MainLayout from "../components/MainLayout";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  // TextField,
  // TextField,
  // Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Hero from "../assets/hubHero.png";
import { HashLink, NavHashLink } from "react-router-hash-link";

import { styled } from "@mui/system";
import Marquee from "react-fast-marquee";
// import SolutionsLab from "../assets/solutionsLab.webp";
import SolutionsLab from "../assets/solutionsLab.png";
import AppBox from "../assets/applicationBox.svg";
import TalentImage from "../assets/talent.jpeg";
import AcceleratorImage from "../assets/accelerator.jpg";
import TecHubCatalystImage from "../assets/tecHubCatalyst.jpeg";
import CommunityImage from "../assets/community.jpeg";
import HomeHero from "../assets/homeHero.jpg";
import useFetch from "../components/useFetch";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ApplicationClosed,
  CatalystLinkBanner,
  Concept,
  HubHero2,
  MeethubPlatform,
} from "../assets/";

import { Carousel } from "react-responsive-carousel";
import ReactGA from "react-ga4";

const StyledHeader = styled(Typography)(() => ({
  background: "-webkit-linear-gradient(180deg, #FFBA00, #FF6110)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: "bold",
}));

// const SectionTitle = styled(Typography)(() => ({
//   fontSize: "12px",
//   textAlign: "center",
//   py: "1rem",
// }));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F6F2E4",
  padding: theme.spacing(1),
  borderRadius: "16px",
  border: "1px solid #E7E2D2",
  boxShadow: "none",
  height: "100%",
}));

const Programs = [
  {
    caption: "Talent Program",
    img: TalentImage,
    title: "Talent Program",
    text: "HUBiquitous Talent Program improves the technical skills of the African tech-communities and enhances the employment and job opportunities.",
    link: "/hubiquitous-programs#talent-program",
  },
  {
    caption: "Accelerator Program",
    img: AcceleratorImage,
    title: "Accelerator Program",
    text: "The Accelerator Program supports entrepreneurs with the creation of new products and services in collaboration with European and African DIHs/TechHubs.",
    link: "/hubiquitous-programs#accelerator-program",
  },
  {
    caption: "Community Program",
    img: CommunityImage,
    title: "Community Program",
    text: "The Community Program aims to build Africa-Europe Innovation Communities engaging DIHs, start-ups & entrepreneurs, SMEs/industries, incubators and accelerators, diasporas, youth and women communities.",
    link: "/hubiquitous-programs#community-program",
  },
  {
    caption: "TecHub Catalyst Program ",
    img: TecHubCatalystImage,
    title: "TecHub Catalyst Program ",
    text: "The Techub Catalyst Program aims to elevate 10 African Tech Hubs by enhancing their IoT capabilities and fostering the development of sustainable value-added services. This initiative empowers the hubs for technological innovation, skill enhancement, and entrepreneurial growth within their local ecosystems.",
    link: "/techub-catalyst-program",
  },
];

const Solutions = [
  {
    title: "Solution Lab",
    text: "The Solution Lab is a developmental &amp; testing infrastructure composed of generic hardware/software, networking and computing resources, APIs, tools/platforms and supporting learning material, which can be used to develop, experiment and innovate disruptive IoT, Big Data and AI technologies. The Solution Lab will be hosted by the African DIHs.",
    link: "innovation-enablers#solutions-lab",
    image: SolutionsLab,
  },
  {
    title: "Application Business Box",
    text: "The Application Business Box is a “ready to use” package of technical, training and business support to entrepreneurs and startups. The box is constituted of three essential elements (i.e. hardware & software kits, training and coaching supports, as well as business development tools and templates) needed by entrepreneurs and startups for rapid prototyping and creation of new businesses.",
    link: "innovation-enablers#app-business-box",
    image: AppBox,
  },
  {
    title: "Meethub Platform",
    text: "HUBiquitous MeetHub is an online collaboration platform to boost Africa-Europe innovation partnerships as well as the creation of a joint DIHs and startup innovation ecosystem. The MeetHub enables technical &amp; business networking and match-making among ecosystem actors, and the development of collaborations. It is also an active knowledge repository base through which DIHs will have access to online training, best practices, etc.",
    link: "innovation-enablers#meethub-platform",
    image: MeethubPlatform,
  },
];

// const LastestBlogs = () => {
//   const [latestPosts, setLatestPosts] = React.useState<any>([]);
//   const { data, isLoading, isError } = useFetch(
//     "/blog-posts?populate[0]=image"
//   );

//   const handleSortByDate = (a: any, b: any) => {
//     const dateA = new Date(a.attributes.publishedAt);
//     const dateB = new Date(b.attributes.publishedAt);
//     if (dateA < dateB) return 1;
//     else if (dateA > dateB) return -1;
//     return 0;
//   };
//   React.useEffect(() => {
//     if (data?.data) {
//       setLatestPosts(data.data.sort(handleSortByDate).slice(0, 3));
//     }
//   }, [data]);

//   if (isLoading) {
//     return <Typography variant="body1">Loading...</Typography>;
//   }
//   if (isError) {
//     return (
//       <Typography variant="body1" color="error">
//         Error fetching latest blog posts
//       </Typography>
//     );
//   }

//   return (
//     <Carousel autoPlay interval={3000} showThumbs={false} infiniteLoop>
//       {latestPosts?.map((item: any, index: number) => (
//         <Grid
//           container
//           component="div"
//           justifyContent="right"
//           alignItems="center"
//           sx={{
//             width: "100%",
//             height: "285px",
//             backgroundImage: `linear-gradient(90deg, #8B8A8A00, #191919 ), url(${item?.attributes?.image?.data?.attributes?.url})`,
//             backgroundPosition: "center",
//             backgroundSize: "cover",
//             backgroundRepeat: "no-repeat",
//             borderRadius: "20px",
//           }}
//         >
//           <Grid item xs={12} md={8}></Grid>
//           <Grid item xs={12} md={4} sx={{ py: "4rem", px: "1rem" }}>
//             <Typography
//               variant="body1"
//               gutterBottom
//               sx={{ color: "#FFF", fontWeight: "bold" }}
//             >
//               {item?.attributes?.title}
//             </Typography>
//             <Typography variant="body2" gutterBottom sx={{ color: "#FFBA00" }}>
//               {new Date(item?.attributes?.publishedAt).toDateString()}
//             </Typography>
//           </Grid>
//         </Grid>
//       ))}
//     </Carousel>
//   );
// };

function Home() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
  const { data, isLoading, isError } = useFetch("/partners?populate[0]=logo");
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Home page",
    });
  }, []);

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        {/* HERO SECTION */}
        <Box
          sx={{
            // height: "420px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // gap: 2,
            py: 3,
            backgroundImage: `url(${HomeHero})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment:"fixed",
            // backgroundRepeat: "no-repeat",
            backgroundBlendMode: "darken",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          />
          <Container maxWidth="lg" sx={{ zIndex: 1 }}>
            <Grid
              container
              alignItems="center"
              // justifyContent="center"
              // flexDirection={{ xs: "column-reverse", md: "row" }}
              // spacing={3}
              my={5}

              // mb={3}
              // sx={{ pb: "3rem", pl: { xs: "1rem", md: "5rem" } }}
            >
              <Grid item md={12} sx={{ textAlign: "center" }}>
                <Typography
                  variant="h3"
                  gutterBottom
                  sx={{ fontWeight: "bold", color: "#fff" }}
                >
                  Digital Innovation Across{" "}
                  {/* {isMobileView && (
                    <span
                      style={{
                        background:
                          "-webkit-linear-gradient(180deg, #FFBA00, #FF6110)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontWeight: "bold",
                      }}
                    >
                      Africa and Europe
                    </span>
                  )} */}
                  {/* {!isMobileView && ( */}
                    <StyledHeader variant="h3">Africa and Europe</StyledHeader>
                  {/* // )} */}
                </Typography>
                <Grid container justifyContent="center">
                  <Grid item xs={12} sm={11} md={8} >
                    <Typography variant="h5" sx={{ color: "#fff", fontWeight: "bold" }} gutterBottom>
                      Fostering collaboration between African and European
                      Digital Innovation Hubs to empower startup ecosystems.
                    </Typography>
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  component={Link}
                  to={"/hubiquitous-programs"}
                  sx={{ color: "white", mt: 2 }}
                >
                  Discover the project
                </Button>
                {/* <Stack spacing={2} direction="row" sx={{ py: 4 }}>
            </Stack> */}
              </Grid>
              {/* <Grid item md={6}>
                <img
                  src={HomeHero}
                  alt="Hubiquitous"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "20px",
                    filter: "brightness(1.20)",
                  }}
                />
              </Grid> */}
            </Grid>
          </Container>
        </Box>

        {/* PROGRAMS and PROJECTS */}
        <Container maxWidth="lg">
          {/* PROJECT */}
          <Grid container alignItems="center" sx={{ py: 1 }} spacing={5}>
            <Grid item md={5}>
              <Typography variant="body1" gutterBottom>
                THE HUBIQUITOUS PROJECT
              </Typography>

              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: "bold", color: "#2D2D2D", py: "1rem" }}
              >
                Learn More About
                <StyledHeader variant="h4">Our Project</StyledHeader>
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ color: "#505050" }}
              >
                HUBiquitous is a Horizon 2020’s Innovation Action aiming at
                creating a joint Africa-Europe Startup & Innovation Ecosystem
                for long-term collaborations and partnerships. The project has
                the ambition to increase the technology level and capacity
                building of 30 local Digital Innovation Hubs (DIHs)/Tech Hubs in
                5 African countries.
              </Typography>
              <Stack spacing={2} direction="row" sx={{ py: 4 }}>
                <Button
                  variant="contained"
                  component={Link}
                  to="/hubiquitous-programs"
                  color="secondary"
                  sx={{ color: "white" }}
                >
                  Read more
                </Button>
                <Button
                  variant="outlined"
                  component={Link}
                  to="/contact-us"
                  color="secondary"
                >
                  Contact us
                </Button>
              </Stack>
            </Grid>
            <Grid item md={7}>
              {/* <img
                src={Concept}
                alt="Hubiquitous-Project-Concept"
                style={{ maxWidth: "100%" }}
              /> */
              <iframe src="https://www.youtube.com/embed/GPVgYF0RF8o?si=1cDRYM50ijDWHGRB" width="560" height="315" allow="autoplay"></iframe>
              }
            </Grid>
          </Grid>

          <Grid container sx={{ py: 1 }}>
            <Grid item xs={12} sm={10} md={8}>
              <Typography
                variant="body1"
                gutterBottom
                // sx={{ fontSize: "12px" }}
              >
                HUBIQUITOUS PROGRAMS
              </Typography>
              <Typography variant="h3" gutterBottom sx={{ py: "1rem" }}>
                Accelerating Innovation for a
                <StyledHeader variant="h3">
                  Sustainable African Future
                </StyledHeader>
              </Typography>

              <Typography variant="body1">
                Empowering innovation and sustainable technological solutions in
                Africa through the creation of a joint Africa-Europe Startup &
                Innovation Ecosystem for long-term collaborations and
                partnerships between these continents.
              </Typography>
              <Button
                variant="contained"
                component={Link}
                to={"/hubiquitous-programs"}
                sx={{ color: "white", my: "2rem" }}
              >
                More about our programs
              </Button>
            </Grid>
          </Grid>
        </Container>
        {/* PROGRAMS LIST */}
        <Marquee pauseOnHover>
          <Box display="flex" gap="1.5rem" mb={4}>
            {Programs.map((program, index) => (
              <Box
                key={index}
                width="320px"
                sx={{
                  marginRight: Programs.length - 1 === index ? "5rem" : "",
                }}
              >
                <HashLink
                  smooth
                  to={program.link}
                  style={{ textDecoration: "none" }}
                >
                  <Item
                    sx={{ cursor: "pointer", textDecoration: "none" }}
                    // onClick={() => navigate(program.link)}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        backgroundImage: `url(${program.img})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "rgba(50, 50, 50, 0.5)",
                        backgroundBlendMode: "multiply",
                        textDecoration: "none",
                        color: "#FFF",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        py="5rem"
                        sx={{
                          // pt: "12px",
                          fontWeight: "bold",
                          textAlign: "center",
                          color: "#FFF",
                        }}
                        gutterBottom
                      >
                        {program.caption}
                      </Typography>
                    </Box>
                    <Box>
                      {/* <Typography
                      variant="body1"
                      sx={{ pt: "12px", fontWeight: "bold" }}
                      gutterBottom
                    >
                      {program.title}
                    </Typography> */}
                      <Typography
                        variant="body2"
                        gutterBottom
                        sx={{ pb: "10px", lineHeight: "149%" }}
                      >
                        {program.text}
                      </Typography>
                    </Box>
                  </Item>
                </HashLink>
              </Box>
            ))}
          </Box>
        </Marquee>

        <Divider></Divider>
        {/* INNOVATION ENABLERS */}
        <Box sx={{ py: "1rem" }}>
          <Container maxWidth="lg">
            <Grid container alignItems="center" justifyContent="center">
              <Grid item sx={{ pb: "3rem" }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ textAlign: "center", py: "1rem" }}
                >
                  INNOVATION ENABLERS
                </Typography>

                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    // color: "#2D2D2D",
                    textAlign: "center",
                  }}
                >
                  How We Create an Environment Condusive to Innovation
                </Typography>
                {/* <StyledHeader variant="h4" sx={{ textAlign: "center" }}>
                  Condusive to Innovation
                </StyledHeader> */}
              </Grid>
            </Grid>

            <Grid container spacing={5} sx={{ pb: "1rem" }}>
              {Solutions.map((solution, index) => (
                <Grid item sm={12} md={6} lg={4} key={index}>
                  <Item
                    sx={{
                      p: "0.7rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box style={{ width: "100%", height: "250px" }}>
                      <img
                        src={solution.image}
                        alt={solution.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Box flexGrow={1}>
                      <Typography
                        variant="body1"
                        sx={{ pt: "12px", fontWeight: "bold" }}
                        gutterBottom
                      >
                        {solution.title}
                      </Typography>
                      <Box>
                        <Typography
                          variant="body2"
                          gutterBottom
                          sx={{ pb: "10px", textAlign: "justify" }}
                        >
                          {solution.text}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      // color="secondary"
                      component={NavHashLink}
                      sx={{ width: "fit-content", textDecoration: "none" }}
                      to={solution.link}
                    >
                      Read more
                    </Button>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* LATEST BLOG POSTS */}
        {/* <Box>
          <Container maxWidth="lg">
            <Grid container alignItems="center" justifyContent="center">
              <Grid item sx={{ pb: "3rem" }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ textAlign: "center", py: "1rem" }}
                >
                  OUR LATEST POSTS
                </Typography>

                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "#2D2D2D",
                    textAlign: "center",
                  }}
                >
                  Press Release &{" "}
                  <StyledHeader variant="h4" sx={{ textAlign: "center" }}>
                    Blog Articles
                  </StyledHeader>
                </Typography>
              </Grid>
            </Grid>
            <Container
              maxWidth="md"
              sx={{
                pb: "4rem",
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <LastestBlogs />
              <Button
                component={Link}
                to="/blog"
                variant="outlined"
                color="secondary"
                style={{ width: "fit-content" }}
              >
                View all posts
              </Button>
            </Container>
          </Container>
        </Box> */}

        {/* PARTNERS */}
        <Divider></Divider>
        <Box sx={{ py: "2rem", px: "1rem" }}>
          {/* <Container
            maxWidth="lg"
            
          > */}
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} mb={2}>
              <Typography
                variant="body1"
                gutterBottom
                align="center"
                // sx={{ fontSize: "12px" }}
              >
                OUR PARTNERS
              </Typography>
            </Grid>
            {isLoading && <Typography variant="body1">Loading...</Typography>}
            {isError && (
              <Typography variant="body1" color={"error"}>
                Error fetching partners images
              </Typography>
            )}
            {data?.data?.map((partner: any, index: number) => (
              <>
                <img
                  key={index}
                  src={partner?.attributes?.logo?.data?.attributes?.url}
                  alt={`${partner?.attributes?.name}`}
                  style={{
                    width:
                      partner?.attributes?.name?.toUpperCase() ===
                        "CLOUDPORT" ||
                      partner?.attributes?.name === "igHub" ||
                      partner?.attributes?.name === "Inneurope" ||
                      partner?.attributes?.name === "WaziUp"
                        ? "10rem"
                        : "6rem",
                    height:
                      partner?.attributes?.name?.toUpperCase() ===
                        "CLOUDPORT" ||
                      partner?.attributes?.name === "igHub" ||
                      partner?.attributes?.name === "Inneurope" ||
                      partner?.attributes?.name === "WaziUp"
                        ? "10rem"
                        : "",

                    objectFit: "contain",
                  }}
                />
                {/* igHub */}
                {/* <Typography>{partner?.attributes?.name}</Typography> */}
              </>
            ))}
          </Grid>
          {/* </Container> */}
        </Box>
        <Divider></Divider>

        {/* newsletter section */}
        <Box sx={{ bgcolor: "#F6F2E4", py: "1rem" }}>
          <Container maxWidth="lg">
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12} sx={{ pb: "1rem" }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  SUBSCRIBE TO OUR MAILING LIST
                </Typography>
                <Container maxWidth="lg">
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      color: "#2D2D2D",
                      textAlign: "center",
                    }}
                  >
                    Sign up to receive news and updates about HUBiquitous
                    {/* <StyledHeader variant="h4">HUBiquitous</StyledHeader> */}
                  </Typography>
                </Container>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: { xs: "100%", md: "50%" },
                margin: "auto",
              }}
            >
              <iframe
                src="https://embeds.beehiiv.com/a1322e29-f595-46ac-9b92-0ed6855ad2bd?slim=true"
                data-test-id="beehiiv-embed"
                height="52"
                frameBorder="0"
                scrolling="no"
                title="newsletter subscription"
                style={{
                  // margin: "0px",
                  // borderRadius: "0px",
                  width: "100%",
                  backgroundColor: "transparent",
                }}
              ></iframe>
            </Box>
          </Container>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default Home;
