import { Box, Container,Card, Grid, Pagination, LinearProgress, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

import useFetch from "../components/useFetch";

const imagesLink = [
  {
    id: 1,
    url: "https://drive.google.com/file/d/1dSWSKpqx2FUEVwbYbQK_2MRGv5sEtJNY/preview",
  },
  {
    id: 2,
    url: "https://drive.google.com/file/d/1l3mDpP36dNOJOhEMMCvEAow7fHrXqU2M/preview",
  },
  {
    id: 3,
    url: "https://drive.google.com/file/d/1GB8sm6-Pj9r1_J_KifaDMV6yQLSurs37/preview",
  },
];
function Downloads() {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  // const { data, isLoading, isError } = useFetch("/media?populate[0]=image");
  const { data, isLoading, isError } = useFetch(
    "/media?filters[type][$eq]=files&populate[0]=file",
    24, // Page size
    currentPage // Current page
  );

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    console.log(data);
    console.log(isLoading);
  }, [data]);
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Downloads",
    });
  }, []);

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            fontWeight="bold"
            gutterBottom
            align="center"
            mt={5}
          >
            Our Downloads
          </Typography>

          {<Grid container spacing={3} sx={{ py: "3rem" }}>
            {isLoading ? (
              <LinearProgress />
            ) : isError ? (
              <Typography>An error occurred!</Typography>
            ) : (
              data?.data?.length > 0 &&
              data?.data?.map((item: any) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  {/* Card component for each download item */}
                  <Card 
                    sx={{ 
                      display: "flex", 
                      flexDirection: "column", 
                      gap: 1, 
                      padding: 2, 
                      cursor: "pointer", 
                      height: '100%' // Ensure card takes full height
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{ minHeight: "3rem" }}
                    >
                      <Link
                        to={item?.attributes?.file?.data?.attributes?.url}
                        target="_blank"
                        style={{ color: "inherit" }}
                      >
                        {item?.attributes?.name || ""}
                      </Link>
                    </Typography>
                    <Typography variant="body1" color="#505050">
                      {item?.attributes?.description || ""}
                    </Typography>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>}  {data && (
            <Pagination
              count={data.meta.pagination.pageCount}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}
            />
          )}
        </Container>
      </Box>
    </MainLayout>
  );
}

export default Downloads;
