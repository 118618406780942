import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import MainLayout from "../components/MainLayout";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Wazidev,
  Waziapi,
  Wazicloud,
  Wazigate,
  Wazicourse,
  Wazidevkit,
} from "../assets/";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

const APIFeatures = [
  {
    text: "The preliminaries",
  },
  {
    text: "How to access control",
  },
  {
    text: "The device management",
  },
  {
    text: "How to see the list of users",
  },
  {
    text: "How to connect you Gateway to Waziup platform",
  },
  {
    text: "How to create and manage notifications",
  },
  {
    text: "How to access the platform via MQTT",
  },
];
const IoTKitFeatures = [
  {
    text: "1 Waziup development board with embedded LoRa module (Wazidev)",
  },
  {
    text: "1 USB cable",
  },
  {
    text: "1 battery case",
  },
  {
    text: "1 DHT sensor",
  },
  {
    text: "10 jumper cables",
  },
];

function WazihubTechnologies() {
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Technologies page" });
  },[])
 
  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Box
            textAlign={"center"}
            pb={4}
            mt={5}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Typography variant="body2" color={"#505050"}>
              THE PROJECT
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              WAZIUP <span style={{ color: "#FFBA00 " }}>Technologies</span>
            </Typography>
            <Typography variant="body1" color={"#505050"}>
            HUBiquitous builds on technologies developed by WAZIUP, known for its "out-of-box" complete solutions spanning from sensor nodes to applications. These solutions are particularly adapted to tough conditions with low power, and long-range applications.
            </Typography>
          </Box>
          <Grid container spacing={4} py={4}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  borderRadius: "16px",
                  border: "1px solid #B2AEA1",
                  background: "#FAF5E1",
                  p: 3,
                }}
              >
                <img
                alt=""
                  src={Wazidev}
                  style={{
                    width: "255px",
                    height: "231px",
                    borderRadius: "10px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h4" fontWeight={"bold"}>
                    WaziDev
                  </Typography>
                  <Typography variant="body1">
                    WaziDev is a development board with an embedded LoRa module
                    that allows you to simply develop IoT sensor nodes as well
                    as IoT actuator nodes. It is an ideal solution for startups
                    and entrepreneurs who want to rapidly prototype their IoT
                    applications.
                  </Typography>
                  <Button
                    component={Link}
                    to="https://lab.waziup.io/resources/waziup/wazidev#overview"
                    target="_blank"
                    variant="contained"
                    sx={{
                      width: "fit-content",
                      color: "white",
                    }}
                  >
                    WAZIDEV user manual
                  </Button>
                </Box>
              </Box>
            </Grid>
            {/* Wazigate */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  borderRadius: "16px",
                  border: "1px solid #B2AEA1",
                  background: "#FAF5E1",
                  p: 3,
                }}
              >
                <img
                  src={Wazigate}
                  style={{
                    width: "255px",
                    height: "231px",
                    borderRadius: "10px",
                  }}
                  alt=""
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h4" fontWeight={"bold"}>
                    WaziGate
                  </Typography>
                  <Typography variant="body1">
                    WaziGate is a powerful edge computing platform that enables
                    you to run your applications locally even without an
                    internet connection. It is the ideal IoT LoRa Gateway for
                    your remote IoT applications. WaziGate can cover up to 100
                    IoT end devices using the LoRa radio network and can also
                    offer the ability for actuation. WaziGate is an affordable
                    solution for emerging countries’ needs.
                  </Typography>
                  <Button
                    component={Link}
                    to="https://lab.waziup.io/resources/waziup/wazigate#overview"
                    target="_blank"
                    variant="contained"
                    sx={{
                      width: "fit-content",
                      color: "white",
                    }}
                  >
                    WaziGate user manual
                  </Button>
                </Box>
              </Box>
            </Grid>
            {/* WaziCloud */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  borderRadius: "16px",
                  border: "1px solid #B2AEA1",
                  background: "#FAF5E1",
                  p: 3,
                }}
              >
                <img
                  src={Wazicloud}
                  style={{
                    width: "255px",
                    height: "231px",
                    borderRadius: "10px",
                  }}
                  alt=""
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h4" fontWeight={"bold"}>
                    WaziCloud
                  </Typography>
                  <Typography variant="body1">
                    WaziCloud offers a secure, modular and open platform that
                    allows you to create and deploy your IoT applications in the
                    cloud and on the edge computing gateway. Our open IoT cloud
                    platform helps you to manage your users and their privacy,
                    turn data into actionable insights and can handle unreliable
                    and intermittent internet connection. WaziCloud gives a
                    complete end-to-end WAZIUP experience and generated data can
                    be sent, received, stored and analyzed with the possibility
                    to use SMS or mobile application for notifications.
                  </Typography>
                  <Button
                    component={Link}
                    to="https://www.waziup.io/"
                    target="_blank"
                    variant="contained"
                    sx={{
                      width: "fit-content",
                      color: "white",
                    }}
                  >
                    WAZIUP website
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider />

          <Grid container spacing={4} py={4}>
            {/* API */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  borderRadius: "16px",
                  border: "1px solid #B2AEA1",
                  background: "#F4F4F4",
                  p: 3,
                }}
              >
                <img
                  src={Waziapi}
                  style={{
                    width: "255px",
                    height: "231px",
                    borderRadius: "10px",
                  }}
                  alt=""
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Typography variant="h4" fontWeight={"bold"}>
                    API
                  </Typography>
                  <Typography variant="body1" color={"#505050"}>
                    The API documentation is available to explore and interact
                    with all the endpoints of Waziup APIs with details for:
                  </Typography>
                  {/* API features */}
                  <Box display="flex" flexDirection="column" gap={2}>
                    {APIFeatures.map((benefit, index) => (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems={"center"}
                        gap={1}
                        key={index}
                      >
                        <CheckCircleIcon
                          sx={{ color: "#FF6110", fontSize: "20px" }}
                        />
                        <Typography variant="body1" color={"#505050"}>
                          {benefit.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Button
                   component={Link}
                   to="https://lab.waziup.io/"
                   target="_blank"
                   variant="contained"
                   sx={{
                     width: "fit-content",
                     color: "white",
                   }}
                  >
                    WAZIUP website
                  </Button>
                </Box>
              </Box>
            </Grid>
            {/* Solution Box */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  borderRadius: "16px",
                  border: "1px solid #B2AEA1",
                  background: "#F4F4F4",
                  p: 3,
                }}
              >
                <img
                  src={Wazidevkit}
                  style={{
                    width: "255px",
                    height: "231px",
                    borderRadius: "10px",
                  }}
                  alt=""
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Typography variant="h4" fontWeight={"bold"}>
                  Solution Box
                  </Typography>
                  <Typography variant="body1" color={"#505050"}>
                    WAZIHUB provides free Solution Box to makers to
                    facilitate the rapid prototyping of their solutions. It
                    includes:
                  </Typography>
                  {/* Solution Box features */}
                  <Box display="flex" flexDirection="column" gap={2}>
                    {IoTKitFeatures.map((benefit, index) => (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems={"center"}
                        gap={1}
                        key={index}
                      >
                        <CheckCircleIcon
                          sx={{ color: "#FF6110", fontSize: "20px" }}
                        />
                        <Typography variant="body1" color={"#505050"}>
                          {benefit.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* Courses */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  borderRadius: "16px",
                  border: "1px solid #B2AEA1",
                  background: "#F4F4F4",
                  p: 3,
                }}
              >
                <img
                  src={Wazicourse}
                  style={{
                    width: "255px",
                    height: "231px",
                    borderRadius: "10px",
                  }}
                  alt=""
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Typography variant="h4" fontWeight={"bold"}>
                    Courses
                  </Typography>
                  <Typography variant="body1" color={"#505050"}>
                    The University of PAU in France developed online tutorials
                    on Arduino, Sensors, and LoRa technologies. The main
                    objective of these online tutorials is to provide
                    comprehensive and guided training materials to be used in
                    training, hackathons, bootcamps, entrepreneur’s days,… that
                    are organized by WAZIUP/WAZIHUB across Africa.
                  </Typography>

                  <Button
                    component={Link}
                    to="https://cpham.perso.univ-pau.fr/LORA/HUBIQUITOUS/solution-lab/arduino-lora-tutorial/"
                    target="_blank"
                    variant="contained"
                    sx={{
                      width: "fit-content",
                      color: "white",
                    }}
                  >
                    View tutorials
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </MainLayout>
  );
}

export default WazihubTechnologies;
