import * as React from "react";
import { Box, Toolbar, createTheme, ThemeProvider } from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";

function MainLayout(props: { children: any }) {
  const { children } = props;
  // const theme = createTheme({});
  return (
    // <ThemeProvider theme={theme}>
    <Box sx={{ display: "block" }}>
      <Navbar />

      <Box>
        <Toolbar />

        {/* CONTENT */}
        {children}

        {/* FOOTER */}
        <Footer />
      </Box>
    </Box>
    // </ThemeProvider>
  );
}

export default MainLayout;
