import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import MainLayout from "../components/MainLayout";
import HeroImage from "../assets/talent-hero-bg.jpg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import ReactGA from "react-ga4";

const applicationLink = (
  <Link
    className="orange-link"
    to="https://docs.google.com/forms/d/e/1FAIpQLSeVcod668fT8OXMqye-cZa9kPnqTaor8PNpx8kw3RxkxH7_Cg/viewform"
    target="_blank"
  >
    application form
  </Link>
);

const Timeline = [
  {
    id: 1,

    title: "Topics",
    description:
      "The Hubiquitous Talent Program will cover the following topics:",
    details: [
      {
        id: 1,
        description: "Fundamentals of IoT,",
      },
      {
        id: 2,
        description: "Design Thinking,",
      },
      {
        id: 3,
        description: "Introduction to Basic Electronics,",
      },
      {
        id: 4,
        description: "Development Boards and Sensors,",
      },
      {
        id: 5,
        description: "Getting started with Waziup technologies,",
      },
      {
        id: 6,
        description: "IoT Cloud platforms,",
      },
      {
        id: 7,
        description: "AI and Big Data for IoT.",
      },
    ],
  },
  {
    id: 2,
    backgroundColor: "#eba834",
    title: "Requirements",
    description:
      "Participants are required to have the following for a smooth training:",
    details: [
      {
        id: 1,
        description:
          "Some engineering or technical background (Electrical/Electronics Engineering, Computer Science, and related fields/disciplines etc…),",
      },
      {
        id: 2,
        description: "Basic knowledge in programming,",
      },
      {
        id: 3,
        description: "Basic knowledge in electronics,",
      },
      {
        id: 4,
        description: "Working personal computer.",
      },
    ],
  },
  {
    id: 3,
    backgroundColor: "#eba834",
    title: "Objectives",
    description:
      "The HUBiquitous Talent Program pursues the following objectives:",
    details: [
      {
        id: 1,
        description:
          "To provide a hands-on approach to IoT Solutions Design and Deployment with Waziup,",
      },
      {
        id: 2,
        description:
          "To equip participants with AI and Big Data Visualization skills,",
      },
      {
        id: 3,
        description:
          "To promote the use of Design Thinking for Problem-Solving,",
      },
      {
        id: 4,
        description: "To inculcate proper Product Development methodologies,",
      },
      {
        id: 5,
        description: "Enhance employability within the ecosystem.",
      },
    ],
  },
];
const Details = ({
  backgroundColor,
  details,
}: {
  backgroundColor?: string;
  details?: Array<{ title: string; description: string }>;
}) => {
  return (
    <Grid container spacing={3}>
      {Timeline.map((item) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "column" },
            gap: "1rem",
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              color: "white",
              width: "61px",
              height: "61px",
              padding: "10px",
              backgroundColor: "#D9D9D9",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {item.id}
            </Typography>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              flexGrow: 1,

              padding: "1rem",
            }}
          >
            <Typography variant="h5" fontWeight="bold" color="black">
              {item.title}
            </Typography>
            {item.details.map((detail) => (
              <Box key={detail.id} display="flex" gap={1}>
                <CheckCircleIcon
                  sx={{ fontSize: "15px", marginTop: "5px", color: "#FFBA00" }}
                />
                <Typography
                  variant="body1"
                  align={item.id === 2 && detail.id === 1 ? "left" : "justify"}
                >
                  {detail.description}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

function TalentProgram() {
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Talent program page",
    });
  }, []);

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            backgroundAttachment: "fixed",
            backgroundImage: `url(${HeroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h3" fontWeight="bold" sx={{ color: "#FFBA00" }}>
            Talent Program
          </Typography>
        </Box>

        <Container>
          {/* <Typography variant="body1" gutterBottom>
            Call for Talents
          </Typography> */}
          <Typography
            variant="h4"
            fontWeight={"bold"}
            gutterBottom
            sx={{ color: "#FFBA00" }}
          >
            Call For Applications - Hubiquitous IoT Talent Program
          </Typography>

          <Box mb={2}>
            <Typography variant="body1" align="justify" gutterBottom>
              As part of the HUBiquitous Project, we are launching a Call for
              Applications for our Talent Program!
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom mb={3}>
              The Talent Program will span a period of four (4) months, starting
              in{" "}
              <span style={{ fontWeight: "bold" }}>
                April and ending in July of 2023.
              </span>{" "}
              It includes an online Learning Program and a Bootcamp/Hackathon to
              be organized in 5 African countries, namely{" "}
              <span style={{ fontWeight: "bold" }}>
                Kenya, Ghana, Nigeria, Tanzania and Egypt.
              </span>{" "}
              This call is therefore open to IoT hobbyists/enthusiasts,
              developers, and engineers (or people with technical backgrounds)
              from these countries.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom mb={4}>
              The Learning Program covers our IoT Training Course, which is
              designed for people looking to get into the world of IoT for the
              first time or to improve the knowledge and skills they already
              have in this field. The specific topics covered in this course are
              outlined in the Topics section below. The content of the course
              will be available on our online learning platform, the Learning
              Center, to which selected applicants will be given access. The
              Learning Program will include online training sessions and
              hands-on sessions at the selected hubs for this Call, which all
              participants will be required to attend. Participants will attend
              the lab sessions at the hubs in their countries that are closest
              to them.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
              Please apply only if you can join all the sessions for the
              learning phase, which includes the physical practical sessions in
              one of the following hubs:
            </Typography>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Ghana: IcodeGH or Foundry Camp
            </Typography>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Nigeria: Colab Kaduna or Start Innovation Hub Uyo
            </Typography>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Tanzania: SUZA or SIDO Arusha
            </Typography>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Kenya: @iLab Africa or Sote Hub
            </Typography>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Egypt: Karakeeb, Athar and SanaaTech
            </Typography>
          </Box>
        </Container>

        <Box>
          <Container>
            <Details />
          </Container>
        </Box>
        <Box
          my={2}
          sx={{
            // backgroundAttachment: "fixed",
            // backgroundImage: `url(${HeroImage})`,

            // height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // mb: 2,
          }}
        >
          {/* <Container> */}
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: "#FFBA00" }}
            align="center"
          >
            Applications closed!
          </Typography>
          {/* </Container> */}
        </Box>
      </Box>
    </MainLayout>
  );
}

export default TalentProgram;
