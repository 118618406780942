import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import MainLayout from "../components/MainLayout";
// import Expert1 from "../assets/expert1.png";
// import Expert2 from "../assets/expert2.png";
// import Expert3 from "../assets/expert3.png";
// import Expert4 from "../assets/expert4.png";
import DefaultProfileImage from "../assets/defaultProfileImage.png";

import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import useFetch from "../components/useFetch";
import ReactGA from "react-ga4";

const ProfileCards = () => {
 
  const { data, isLoading, isError } = useFetch(
    "/Experts?populate[0]=profileImage"
  );
  if (isLoading) {
    return <Typography variant="body1">Loading...</Typography>;
  }
  if (isError) {
    return (
      <Typography variant="body1" color="error">
        Error fetching data
      </Typography>
    );
  }
  return (
    <Grid container spacing={4} py={4} mb={10}>
      {data?.data?.map((item:any) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "center",
            flexGrow: 1,
          }}
          key={item.id}
        >
          <img  
            src={item?.attributes?.profileImage?.data?.attributes?.url || DefaultProfileImage}
            alt={item?.attributes?.name}
            style={{ width: "180px", height: "180px", borderRadius: "50%" }}
          />
          <Typography variant="body1" fontWeight="bold">
            {item?.attributes?.name}
          </Typography>
          <Typography variant="body1">Country: {item?.attributes?.country || ""}</Typography>
          <Typography variant="body1">
            Organization: {item?.attributes?.company}
          </Typography> 
          <Typography variant="body1">Position: {item?.attributes?.position || ""}</Typography>
          <Typography variant="body1">Email: {item?.attributes?.email || ""}</Typography>

          <Link to={item?.attributes?.linkedin || ""} target="_blank">
            <LinkedInIcon />
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

function OurExperts() {
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Our experts page" });
  },[])
  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Box
            textAlign={"center"}
            pb={4}
            mt={5}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Typography variant="body2" color={"#505050"}>
              ABOUT US
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              Meet <span style={{ color: "#FFBA00 " }}>Our Experts</span>
            </Typography>
            <Typography variant="body1">
              Meet the external experts that will support African talents,
              entrepreneurs and startups <br /> to turn their innovative ideas
              into sustainable business models
            </Typography>
          </Box>

          {/* <Grid
            container
            spacing={3}
            display={"flex"}
            justifyContent={"center"}
            py={4}
          >
            <Grid item xs={8} sm={6} md={3}>
              <img
                src={Expert1}
                style={{ width: "100%", borderRadius: "10px" }}
                alt=""
              />
            </Grid>
            <Grid item xs={8} sm={6} md={3} sx={{ mt: { xs: 0, sm: 4 } }}>
              <img
                src={Expert2}
                style={{ width: "100%", borderRadius: "10px" }}
                alt=""
              />
            </Grid>
            <Grid item xs={8} sm={6} md={3}>
              <img
                src={Expert3}
                style={{ width: "100%", borderRadius: "10px" }}
                alt=""
              />
            </Grid>
            <Grid item xs={8} sm={6} md={3} sx={{ mt: { xs: 0, sm: 4 } }}>
              <img
                src={Expert4}
                style={{ width: "100%", borderRadius: "10px" }}
                alt=""
              />
            </Grid>
          </Grid> */}
          <ProfileCards />
        </Container>
      </Box>
    </MainLayout>
  );
}

export default OurExperts;
