import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import MainLayout from "../components/MainLayout";
import ReactGA from "react-ga4";
import ReactPlayer from "react-player/lazy";
import useFetch from "../components/useFetch";


const VideosLinks = [
  {
    id: 1,
    url: "https://www.youtube.com/embed/ZA4jSpZzZQA?si=SSURnMGQMHtQbJ6T",
  },
  {
    id: 2,
    url: "https://www.youtube.com/embed/RzpKL23v7dg?si=VR6uEPe088m-4FIE",
  },
  {
    id: 3,
    url: "https://www.youtube.com/embed/RUD0V0uXPAQ?si=g0m_uooa6WFU3mx7",
  },
  {
    id: 4,
    url: "https://www.youtube.com/embed/afb3JYmBkRs?si=nYQiKPUfNL4jxh8h",
  },
  {
    id: 5,
    url: "https://www.youtube.com/embed/R9ALqUUswek?si=gDhhONyyiph9mKt6",
  },
  {
    id: 6,
    url: "https://www.youtube.com/embed/GPVgYF0RF8o?si=cs3beorx7Cs8SEC4",
  },
  {
    id: 7,
    url: "https://www.youtube.com/embed/399Pa90q0t4?si=_UBJLSs67wQXVsOq",
  },
  {
    id: 8,
    url: "https://www.youtube.com/embed/oSymuZk8MeQ?si=zreSUxZ216bebqWR",
  },
  {
    id: 9,
    url: "https://www.youtube.com/embed/FcxCY_I0iP4?si=RDTVfQE3dS_E_kTT",
  },
  {
    id: 10,
    url: "https://www.youtube.com/embed/008O13YUaDo?si=AM0RmpHINKafRIIB",
  },
  {
    id: 11,
    url: "https://www.youtube.com/embed/008O13YUaDo?si=S54r45w164Gl72Gw",
  },
  {
    id: 12,
    url: "https://www.youtube.com/embed/DgsoiznxC-A?si=Aj1aSpmK2nOtE4T8",
  },
  {
    id: 13,
    url: "https://www.youtube.com/embed/3Gpce2wjo0M?si=XNjZw1IXQP3CobAT",
  },
  {
    id: 14,
    url: "https://www.youtube.com/embed/UpaJOZgb29c?si=Ta0_ctPgcb0qXhGO",
  },
  {
    id: 15,
    url: "https://www.youtube.com/embed/Q_sNxRH-Tsw?si=yzAW6prR6c0rx6ev",
  },
  {
    id: 16,
    url: "https://www.youtube.com/embed/-tQWeQbKhRU?si=oSpkW9-ARqfRNY8-",
  },
  {
    id: 17,
    url: "https://www.youtube.com/embed/4vob93Vo_Fk?si=LsD4DypvWIHBm423",
  },
  {
    id: 18,
    url: "https://www.youtube.com/embed/kUFnsLIZiP8?si=XoeGvzrxNPch9yNS",
  },
  {
    id: 19,
    url: "https://www.youtube.com/embed/wLpQYiJqwg8?si=3eT5dyv8PmnYzERH",
  },
  {
    id: 20,
    url: "https://www.youtube.com/embed/HiPJ9gsC9qk?si=J3H9F5q0IURnNrYr",
  },
  {
    id: 21,
    url: "https://www.youtube.com/embed/6sjsjrPrd8I?si=HOreNZe8Ont3EnqK",
  },
  {
    id: 22,
    url: "https://www.youtube.com/embed/bn-udxcHLuk?si=0uaD9nc6myVlZWFj",
  },
  {
    id: 23,
    url: "https://www.youtube.com/embed/OsimtIsL-5Y?si=-VajeTJsUBoVY1Sa",
  },
  {
    id: 24,
    url: "https://www.youtube.com/embed/xfIntqtnkNA?si=0jkipJogjH_cSh2k",
  },
  {
    id: 25,
    url: "https://www.youtube.com/embed/d8LaAYwG1rI?si=HznbHvMgP50NEgWJ",
  },
  {
    id: 26,
    url: "https://www.youtube.com/embed/fcTS6ExHWKA?si=CouLBZpZHbwvZGly",
  },
  {
    id: 27,
    url: "https://www.youtube.com/embed/yVuCB0ILX7o?si=6zhE9G6m8FbdmGIL",
  },
  {
    id: 28,
    url: "https://www.youtube.com/embed/WVayO7lEaWE?si=M0WNfpA8wPdhefbP",
  },
  {
    id: 29,
    url: "https://www.youtube.com/embed/9zm0pTr00nQ?si=-lqa8iTRgFICeKvj",
  },
  {
    id: 30,
    url: "https://www.youtube.com/embed/ChjNpbjRGUQ?si=22g6YfZ53h3cSYeL",
  },
];

function Videos() {
  const theme = useTheme();
  const { data, isLoading, isError } = useFetch(
    "/media?filters[type][$eq]=video&populate[0]=file"
  );
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Videos page",
    });
  }, []);

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
        <Typography variant="h4" fontWeight="bold" gutterBottom align="center" mt={5}>
                Our Videos
              </Typography>
          <Grid container spacing={3} sx={{ py: "3rem" }}>
            {VideosLinks.map((item) => (
              <Grid item xs={12} md={6} xl={4} key={item.id}>
                {/* <ReactPlayer
                  url={item.url}
                  width="100%"
                  height="315px"
                  controls
                /> */}
                <iframe src={item.url} width="100%" height="315" allow="autoplay"></iframe>
              </Grid>
            ))}
            
          </Grid>
          
        </Container>
      </Box>
    </MainLayout>
  );
}

export default Videos;
