import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import MainLayout from "../components/MainLayout";
import HeroImage from "../assets/talent-hero-bg.jpg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import ReactGA from "react-ga4";
import {
  AgricFood,
  GreenEconomy,
  Health,
  Industry40,
  SmartCities,
} from "../assets";
const applicationLink = (
  <Link
    className="orange-link"
    to="https://docs.google.com/forms/d/e/1FAIpQLSeVcod668fT8OXMqye-cZa9kPnqTaor8PNpx8kw3RxkxH7_Cg/viewform"
    target="_blank"
  >
    application form
  </Link>
);

const ChallengesData = [
  {
    id: 1,
    title: "AGRIFOOD",
    icon: AgricFood,
    description:
      "Agriculture is a driver of growth for any economy. Digitalisation can enhance processes in these sectors involving material flow, information flow or financial capital flow, managed via the supply chain management.",
  },
  {
    id: 2,
    title: "SMART CITIES",
    icon: SmartCities,
    description:
      "Digitalisation has enormous potential to modernize the government and public sector, e.g. by protecting sensitive citizen and government data, increasing trust in government systems or by developing smart city applications.",
  },
  {
    id: 3,
    icon: AgricFood,
    title: "HEALTH",
    description:
      "Digital solutions are necessary to improve primary care assistance, to prevent diseases and disabilities, to cover the specific needs of vulnerable communities, to make the national healthcare systems more efficient and resilient and to improve the patient experience.",
  },
  {
    id: 4,
    icon: Industry40,
    title: "INDUSTRY 4.0",
    description:
      "Different smart technologies are helping to speed up the digital transformation of manufacturing and enable a decisive move towards industry 4.0. The smart factories of the future will organise themselves and enable production that is collaborative, customer-specific and individualised.",
  },
  {
    id: 4,
    icon: GreenEconomy,
    title: "GREEN ECONOMY",
    description:
      "To reach climate goals, the digital sector has to contribute its share and embrace sustainability in all its facets: circular economy models for hardware, climate-neutral CPUs and server centres, software advancements to reduce energy consumption, and many more.",
  },
];
const Challenges = () => {
  return (
    <Grid container spacing={3}>
      {ChallengesData.map((item) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          // md={item.id <= 2 ? 6 : 4}
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "column" },
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
              gap: "1rem",
              flexGrow: 1,

              padding: "1rem",
            }}
          >
            <Box>
              <img src={item.icon} width={100} height={90} />
              <Typography
                variant="h6"
                fontWeight="bold"
                color="#FFBA00"
                align="center"
              >
                {item.title}
              </Typography>
            </Box>
            <Typography variant="body1" align="justify">
              {item.description}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

const BenefitsData = [
  {
    id: 1,
    title: "Application Business Box",
    description: (
      <>
        A “ready-to-use” technical and business support package to entrepreneurs
        and startups, consisting of 3 essential elements:
      </>
    ),
    details: [
      {
        id: 1,
        description: "Prototyping kits & software templates, ",
      },
      {
        id: 2,
        description:
          "Training courses (both technical and business-oriented contents)",
      },
      {
        id: 3,
        description: "Business support templates.",
      },
    ],
  },
  {
    id: 2,
    title: "The MeetHub Platform",
    description: (
      <>
        A platform for IoT stakeholders to collaborate in Europe and Africa. It
        encourages networking and learning between professionals, enthusiasts,
        individuals and businesses. The platform allows to search for DIHs,
        startups and developers in IoT, and to promote innovative IoT solutions.
      </>
    ),
    details: [],
  },
  {
    id: 3,
    title: "Mentoring and Guidance",
    description: (
      <>
        The participants will have access to a permanent service (from Monday to
        Friday) to receive assistance and information.
        <br />
        Furthermore, they will have the chance to connect with a network of
        Mentors, and receive support from them on specific issues upon request.
      </>
    ),
    details: [],
  },
  {
    id: 4,
    title: "Access to the Learning Area",
    description: (
      <>
        Selected participants will have access to the training materials,
        courses, webinars, bootcamps and hackathons covering, among others:
      </>
    ),
    details: [
      {
        id: 1,
        description: "Fundamentals of IoT,",
      },
      {
        id: 2,
        description: "Introduction to Basic Electronics,",
      },
      {
        id: 3,
        description: "Development Boards and Sensors,",
      },
      {
        id: 4,
        description: "Getting started with Waziup technologies",
      },
      {
        id: 5,
        description: "IoT Cloud platform.",
      },
    ],
  },
  {
    id: 5,
    title: "Solution Lab Facilities",
    description: (
      <>
        The Solution Lab provides startups with the capacity to develop, test,
        experiment and pilot innovative products, using IoT, AI and Big Data
        technologies.
        <br />
        Furthermore, the selected projects will have access tothe “Prototyping
        and Learning Centre”, a platform allowing the startupers to quickly
        design and create a prototype, while learning on the way.
      </>
    ),
    details: [],
  },
  {
    id: 6,
    title: "Growth and Investment Readiness",
    description: (
      <>
        The Program foresees 2 interactive online workshops on Investor
        Readiness, covering topics like:
      </>
    ),
    details: [
      {
        id: 1,
        description: "Planning for Fundraising, ",
      },
      {
        id: 2,
        description: "Preparing Fundraising Materials,        ",
      },
      {
        id: 3,
        description: "The Fundraising Process,",
      },
      {
        id: 4,
        description: "The Human Element,",
      },
      {
        id: 5,
        description: "What to Look for in an Investor",
      },
      {
        id: 6,
        description: "Startup Valuation.",
      },
    ],
  },
];
const Benefits = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={3}>
      {BenefitsData.map((item) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "column" },
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              gap: "0.5rem",
              flexGrow: 1,

              padding: "1rem",
            }}
          >
            <Typography variant="h6" fontWeight="bold" color="#FFBA00">
              {item.title}
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
              {item.description}
            </Typography>
            <Box>
              {item.details.length > 0 &&
                item.details.map((detail) => (
                  <Box key={detail.id} display="flex" gap={1}>
                    <CheckCircleIcon
                      sx={{
                        fontSize: "15px",
                        marginTop: "5px",
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography variant="body1">
                      {detail.description}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
const EligibleApplicant = [
  {
    id: 1,
    detail: "Entrepreneurs ",
  },
  {
    id: 2,
    detail: "Startups ",
  },
  {
    id: 3,
    detail: "Scaleups",
  },
  {
    id: 4,
    detail: "SMEs",
  },
];
const EligibleCountries = [
  {
    id: 1,
    detail: "Egypt",
  },
  {
    id: 2,
    detail: "Ghana",
  },
  {
    id: 3,
    detail: "Nigeria",
  },
  {
    id: 4,
    detail: "Tanzania",
  },
];

function TalentProgram() {
  const theme = useTheme();
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Talent program page",
    });
  }, []);

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            backgroundAttachment: "fixed",
            backgroundImage: `url(${HeroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h3" fontWeight="bold" sx={{ color: "#FFBA00" }}>
            Accelerator Program
          </Typography>
        </Box>

        <Container sx={{ py: 2 }}>
          {/* <Typography variant="body1" gutterBottom>
            Call for Talents
          </Typography> */}
          <Typography
            variant="h4"
            fontWeight={"bold"}
            gutterBottom
            sx={{ color: "#FFBA00" }}
          >
            THE FIRST AFRICA-EUROPE ACCELERATOR PROGRAMME IS NOW HERE!
          </Typography>

          <Box px={1}>
            <Typography
              variant="body1"
              align="center"
              fontWeight={"bold"}
              gutterBottom
            >
              The Hubiquitous 1st Open Call will select the 20 most promising,
              sustainable and innovative ideas using IoT technology in five
              different industries: <br /> Agrifood, Smart Cities, Health,
              Industry 4.0 and Green Economy.
            </Typography>
            <Typography
              variant="body1"
              align="center"
              fontWeight={"bold"}
              gutterBottom
            >
              Opening: 30th May 2022, 9.00 am CET
            </Typography>
            <Typography
              variant="body1"
              align="center"
              fontWeight={"bold"}
              gutterBottom
            >
              Deadline: 15th July 2022, 17.00 pm CET
            </Typography>
          </Box>
        </Container>
        <Box sx={{ backgroundColor: "#F6F6F6", py: 2 }}>
          <Container>
            <Typography
              variant="h4"
              fontWeight={"bold"}
              gutterBottom
              align="center"
              sx={{ color: "#FFBA00" }}
            >
              Discover Our Challenges
            </Typography>
            <Challenges />
          </Container>
        </Box>
        <Container sx={{ py: 2 }}>
          <Typography
            variant="h4"
            fontWeight={"bold"}
            gutterBottom
            align="center"
            sx={{ color: "#FFBA00" }}
          >
            Who Can Participate?
          </Typography>
          <Grid container mb={2} spacing={3}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h6"
                fontWeight={"bold"}
                color={theme.palette.primary.main}
              >
                Eligible Applicants
              </Typography>
              <Box>
                {EligibleApplicant.map((item) => (
                  <Box key={item.id} display="flex" gap={1}>
                    <CheckCircleIcon
                      sx={{
                        fontSize: "15px",
                        marginTop: "5px",
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography variant="body1"> {item.detail} </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h6"
                fontWeight={"bold"}
                color={theme.palette.primary.main}
              >
                Eligible African Countries
              </Typography>
              <Box>
                {EligibleCountries.map((item) => (
                  <Box key={item.id} display="flex" gap={1}>
                    <CheckCircleIcon
                      sx={{
                        fontSize: "15px",
                        marginTop: "5px",
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography variant="body1"> {item.detail} </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" gutterBottom align="justify">
                We specially encourage applications from women and young
                talents, as well as African-European partnerships. Applicants
                from EU27 countries may submit applications jointly with an
                African partner if:
              </Typography>
              <Box display="flex" gap={1}>
                <CheckCircleIcon
                  sx={{
                    fontSize: "15px",
                    marginTop: "5px",
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography variant="body1">
                  The lead applicant is the African partner.
                </Typography>
              </Box>
              <Box display="flex" gap={1} mb={2}>
                <CheckCircleIcon
                  sx={{
                    fontSize: "15px",
                    marginTop: "5px",
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography variant="body1">
                  The IoT solutions will be physically deployed in Africa
                  (prototyping and testing activities).
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Typography
              variant="h4"
              fontWeight={"bold"}
              gutterBottom
              align="center"
              sx={{ color: "#FFBA00" }}
            >
              How to Apply
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" gutterBottom>
                  A complete submission includes the following documents:
                </Typography>
                <Box display="flex" gap={1}>
                  <CheckCircleIcon
                    sx={{
                      fontSize: "15px",
                      marginTop: "5px",
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography variant="body1">
                    Part 1: Proposal Template (max. 6 pages){" "}
                    <Link
                      to={
                        "https://hubiquitous.eu/part-2-technical-description-proposal-template-2/"
                      }
                      target="_blank"
                      style={{
                        color: "inherit",
                      }}
                    >
                      (download here)
                    </Link>
                  </Typography>
                </Box>
                <Box display="flex" gap={1} mb={2}>
                  <CheckCircleIcon
                    sx={{
                      fontSize: "15px",
                      marginTop: "5px",
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography variant="body1">
                    Part 2: Administrative Data Sheet (to be completed on the
                    platform)
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" gutterBottom align="justify">
                  Proposals must be submitted by{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Friday, 15th July 2022, 5:00 PM CET
                  </span>{" "}
                  through the dedicated platform.{" "}
                  <Link
                    to={
                      "http://hubiquitous.eu/wp-content/uploads/2022/05/Hubiquitous-1st-Open-Call-Guide-for-Applicants.pdf"
                    }
                    target="_blank"
                    style={{
                      color: "inherit",
                    }}
                  >
                    Download here
                  </Link>{" "}
                  the guide for applicants for more information about the
                  submission procedure and the awarding criteria
                </Typography>

                {/* <Typography variant="body1">
                  
                </Typography> */}
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box sx={{ backgroundColor: "#F6F6F6", py: 2 }}>
          <Container>
            <Typography
              variant="h4"
              fontWeight={"bold"}
              gutterBottom
              align="center"
              sx={{ color: "#FFBA00" }}
            >
              What Will You Get?
            </Typography>
            <Benefits />
          </Container>
        </Box>

        <Container sx={{ py: 2 }}>
          <Typography
            variant="h4"
            fontWeight={"bold"}
            align="center"
            gutterBottom
            sx={{ color: "#FFBA00" }}
          >
            Awards
          </Typography>

          <Box px={1}>
            <Typography
              variant="body1"
              align="center"
              fontWeight={"bold"}
              gutterBottom
              mb={3}
            >
              1st Position: 3,000 €<br />
              2nd Position: 2,000 €<br />
              3rd Position: 1,000 €
            </Typography>
          </Box>
          <Typography
            variant="h4"
            fontWeight={"bold"}
            align="center"
            gutterBottom
            sx={{ color: "#FFBA00" }}
          >
            Contact and Support
          </Typography>
          <Typography
            variant="body1"
            align="center"
            fontWeight={"bold"}
            gutterBottom
          >
            Guide for Applicants
          </Typography>

          <Typography variant="body1" align="center">
            <Link
              to={
                "http://hubiquitous.eu/wp-content/uploads/2022/05/Hubiquitous-1st-Open-Call-Guide-for-Applicants.pdf"
              }
              target="_blank"
              style={{
                color: "inherit",
              }}
            >
              Here
            </Link>{" "}
            you can find the Guide for Applicants, providing information about the submission procedure an the awarding criteria.
          </Typography>
        </Container>
      </Box>
    </MainLayout>
  );
}

export default TalentProgram;
