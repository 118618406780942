import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const MobileNavItems = ({ item }: any) => {
  const [open, setOpen] = useState(false);
  if (item.childrens) {
    return (
      <div
        className={open ? "footer-nav-link-item open" : "footer-nav-link-item"}
      >
        <div className="footer-nav-link-title" onClick={() => setOpen(!open)}>
          {/* <KeyboardArrowDownIcon
            className="toggle-btn"
            onClick={() => setOpen(!open)}
          /> */}
          <span style={{cursor:"pointer"}}>{item.name}</span>
        </div>
        <div className="footer-nav-link-content">
          {item.childrens.map((child: any, index: number) => (
            <MobileNavItems key={index} item={child} />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <a href={item.path || "#"} className="footer-nav-link-item plain">
        {item.name}
      </a>
    );
  }
};

export default MobileNavItems;
