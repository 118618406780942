import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import MainLayout from "../components/MainLayout";
import { Link, useLocation } from "react-router-dom";
import useFetch from "../components/useFetch";
import { Concept } from "../assets";
import ReactGA from "react-ga4";

const ConsortiumList = () => {
  const { data, isLoading, isError } = useFetch("/partners?populate[0]=logo");

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error fetching consortium list</div>;
  }
  return (
    <Grid container spacing={5}>
      {data?.data?.map((partner: any) => (
        <Grid
          item
          xs={12}
          sm={4}
          md={6}
          key={partner?.id}
          sx={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Box
            sx={{ height: "105px", width: "2px", background: "#FF6110" }}
          ></Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <img
              src={partner?.attributes?.logo?.data?.attributes?.url}
              alt={`${partner?.attributes?.name}`}
              style={{
                width: "101.222px",
                aspectRatio: "1/1",
                objectFit: "contain",
              }}
            />
            <Link to={partner?.attributes?.url || ""} target="_blank">
              <Typography variant="body1" fontWeight="bold">
                {partner?.attributes?.name}
              </Typography>
            </Link>

            <Typography variant="body1">
              {partner?.attributes?.description}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

function OurConsortium() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Consortium page",
    });
  }, []);

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          {/* <Grid
            container
            
            display={"flex"}
            flexDirection={{ xs: "column-reverse", md: "row" }}
            alignItems={"center"}
            justifyContent="space-between"
            gap={3}
            pb={4}
            mt={5}
          >
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column" gap={2} flex={1}>
                <Typography variant="body1">The Hubiquitous project</Typography>
                <Typography variant="h4" fontWeight="bold">
                  Create and support{" "}
                  <span style={{ color: "#FFBA00 " }}>
                    Digital Innovation Hubs
                  </span>
                </Typography>

                <Typography variant="body1">
                  Backed by a consortium of five European and four African
                  partners from eight countries, the project seeks to create and
                  support Digital Innovation Hubs (DIH) across selected
                  countries, which will form the foundation of the provision of
                  cutting-edge solutions for Africa’s emerging issues.
                  HUBiquitous builds on the success of two previous H2020
                  projects:{" "}
                  <Link
                    style={{ color: "#FFBA00 " }}
                    to="https://www.waziup.eu"
                    target="_blank"
                  >
                    WAZIHUB (2016-2019)
                  </Link>
                  and{" "}
                  <Link
                    style={{ color: "#FFBA00 " }}
                    to="https://www.waziup.io/research-innovation/projects/wazihub/"
                    target="_blank"
                  >
                    WAZIHUB (2019-March 2021)
                  </Link>
                  .
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <img
                src={Concept}
                style={{
                  width: "100%",
                  height: "289px",
                  // flexShrink: 0,
                  borderRadius: "10px",
                }}
                alt=""
              />
            </Grid>
          </Grid> */}

          {/* Methodology and Solutions section */}
          {/* <Box>
            <Grid
              container
              spacing={6}
              my={4}      
              
            >
              <Grid item xs={12} md={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  
                  sx={{
                    flexGrow:1,
                    height:"100%",
                    borderRadius: "5px",
                    border: "1px solid #F1E9D0",
                    background: "#F6F2E4",
                    padding: 4,
                  }}
                >
                  <Typography variant="body1" fontWeight="bold" gutterBottom>
                    Methodology
                  </Typography>
                  <Typography variant="body1">
                    The project includes a Capacity Building program, an
                    Accelerator program, a Talent program and a Community
                    program to provide training, support and a community for
                    DIHs, SMEs/industries, incubators, youth and women
                    communities.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                
              >
                <Box display="flex"
                flexDirection="column"
                gap={2}
                flexGrow={1}
                sx={{
                  borderRadius: "5px",
                  border: "1px solid #F1E9D0",
                  background: "#F6F2E4",
                  padding: 4,
                }}>
                <Typography variant="body1" fontWeight="bold">
                  Solutions
                </Typography>
                <Typography variant="body1">
                  The project proposes three highly innovative concepts called
                  Innovation Enablers: Solution Lab, Application Business Box
                  and MeetHub online platform. The purpose of these enablers is
                  to provide technical (e.g. IoT, AI and BigData disruptive
                  technologies) as well as entrepreneurial development skills to
                  DIHs and startups/entrepreneurs.
                </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box> */}
          {/* List of consortium section */}
          <Box py={4} mb={10}>
            {/* Heading section */}

            <Box
              textAlign={"center"}
              pb={3}
              display={"flex"}
              flexDirection={"column"}
              gap={2}
            >
              <Typography variant="body1" color={"#505050"}>
                THE CONSORTIUM
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                Learn More About{" "}
                <span style={{ color: "#FFBA00 " }}>The Consortium</span>
              </Typography>
            </Box>
            {/* Lists section */}
            <ConsortiumList />
          </Box>
        </Container>
      </Box>
    </MainLayout>
  );
}

export default OurConsortium;
