import * as React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import Routes from "./AppRoutes";
import { PageProvider } from "./CurrentPageContext";
import theme from "./theme";
import ReactGA from "react-ga4";

console.log("REACT_APP_GA_MEASUREMENT_ID",process.env.REACT_APP_GA_MEASUREMENT_ID);

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || "");
function App() {
  return (
    // <PageProvider>
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <Box>
          <Routes />
        </Box>
      </React.Fragment>
    </ThemeProvider>
    // </PageProvider>
  );
}

export default App;
