import {
  Box,
  Typography,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import { styled } from "@mui/system";

const ColoredHeader = styled(Typography)(() => ({
  background: "-webkit-linear-gradient(180deg, #FFBA00, #FF6110)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: "bold",
}));

export const StyledHeader = ({
  darkText,
  coloredText,
}: {
  darkText: string;
  coloredText: string;
}) => {
  return (
    <Box display={"flex"} justifyContent={"center"} gap={1}>
      <Typography variant="h4" fontWeight="bold">
        {darkText}
      </Typography>
      <ColoredHeader variant="h4">{coloredText}</ColoredHeader>
    </Box>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFBA00",
    },
    secondary: {
      main: "#2D2D2D",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "4px",
          boxShadow: "none",
          fontSize: "18px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: ["Segoe UI"].join(","),
          // color: "#505050",
          color: "#2D2D2D",
        },
        h3: {
          fontWeight: 700,
          fontSize: "40px",
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
