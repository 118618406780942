import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import MainLayout from "../components/MainLayout";
import HeroImage from "../assets/callsPageHeroImg.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import ReactGA from "react-ga4";
import { AppClosedBg } from "../assets";
const Benefits = [
  {
    text: "Improve the hub capacity on IoT technologies through intensive “train the trainers”.",
  },
  {
    text: "Receive a complete IoT hardware and software “Solution Box” to enable the creation of an IoT Lab.",
  },
  {
    text: "Design new tech services which could be a possible additional revenue stream.",
  },
  {
    text: "Participate in the HUBiquitous Innovation Week in Europe to showcase the hubs’ achievements.",
  },
];
const applicationLink = (
  <Link
    className="orange-link"
    to="https://docs.google.com/forms/d/e/1FAIpQLSeVcod668fT8OXMqye-cZa9kPnqTaor8PNpx8kw3RxkxH7_Cg/viewform"
    target="_blank"
  >
    application form
  </Link>
);

const Eligibilities = [
  {
    text: "Have a physical address in an African country or be affiliated with a TecHub or entity based in any African country.",
  },
  {
    text: "Complete application forms & submit all documentation highlighted for upload in the application form.",
  },
  {
    text: "Be legally registered with a valid certificate of registration within the respective African country.",
  },
  {
    text: <>Complete the {applicationLink} before the declared deadline..</>,
  },
];

const Timeline = [
  {
    id: "01",
    backgroundColor: "blue",
    title: "Call for applications",
    details: [
      {
        id: 1,
        title: "Call Open",
        description: "15th Dec 2023 to 25th Jan 2024",
      },
      {
        id: 2,
        title: "Selection Process",
        description: "15th Jan 2024 to 31st Jan 2024",
      },
      {
        id: 3,
        title: "Onboarding & Agreement signing",
        description: "1st Feb 2024 to 16th Feb 2024",
      },
    ],
  },
  {
    id: "02",
    backgroundColor: "#eba834",
    title: "Phase 1 - Train the trainers",
    details: [
      {
        id: 1,
        title: "Train the trainers' online section:",
        description: "19th February to 24th March 2024",
      },
      {
        id: 2,
        title: "Train the trainers' physical workshop:",
        description: "25th March to 29th March 2024",
      },
    ],
  },
  {
    id: "03",
    backgroundColor: "green",
    title: "Phase 2 - IoT certification course & MVPs",
    details: [
      {
        id: 1,
        title: "Short IoT certification course:",
        description: "1st April to 17th May 2024",
      },
      {
        id: 2,
        title: "MVP acceleration:",
        description: "1St April to 17th May 2024",
      },
    ],
  },
  {
    id: "04",
    backgroundColor: "#a33729",
    title: "Phase 3 - Hubiquitous Innovation Week & program closing",
    details: [
      {
        id: 1,
        title: "Innovation Week:",
        description: "27th May to 31st May 2024",
      },
      {
        id: 2,
        title: "Final Review & Program End:",
        description: "3rd June to 15th June 2024",
      },
    ],
  },
];
const TimelineCard = ({
  backgroundColor,
  details,
}: {
  backgroundColor?: string;
  details?: Array<{ title: string; description: string }>;
}) => {
  return (
    <Grid container spacing={3}>
      {Timeline.map((item) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "column" },
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              color: "white",
              width: "61px",
              height: "61px",
              padding: "10px",
              backgroundColor: "#D9D9D9",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {item.id}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              flexGrow: 1,
              backgroundColor: item.backgroundColor,
              padding: "1rem",
            }}
          >
            <Typography variant="h5" fontWeight="bold" color="black">
              {item.title}
            </Typography>
            {item.details.map((detail) => (
              <Box key={detail.id} display="flex" gap={1}>
                <CircleIcon sx={{ fontSize: "10px", marginTop: "5px" }} />
                <Box>
                  <Typography variant="body1" fontWeight="bold" color="black">
                    {detail.title}
                  </Typography>
                  <Typography variant="body1" color="black" fontWeight="bold">
                    {detail.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

function Calls() {
  const theme = useTheme();
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Catalyst program page",
    });
  }, []);

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              height: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              py: 3,
              backgroundImage: `url(${AppClosedBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              // backgroundRepeat: "no-repeat",
              // backgroundBlendMode: "darken",
            }}
          >
            <Typography variant="h3" color="white" align="center">
              Empower your Hubs with the TecHub Catalyst Program
            </Typography>
            <Typography
              variant="h4"
              fontWeight={"bold"}
              color={theme.palette.primary.main}
              align="center"
            >
              Call for Applications is Closed
            </Typography>
            <Typography
              variant="h5"
              color="white"
              fontWeight={"bold"}
              align="center"
            >
              {/* The Evaluation Process is just concluded.
              <br /> */}
              Please click the button below to see the list of selected hubs
            </Typography>
            <Button
            component={Link}
            to="https://storage.googleapis.com/hubiquitous-cms-bucket/Image/selected_List_b75de42336/selected_List_b75de42336.png"
            target="_blank"
              variant="contained"
              style={{
                width: "fit-content",
                // color: "#FFBA00",
                textTransform: "none",
                borderColor: "#FFBA00",
              }}
            >
              View selected Hubs
            </Button>
          </Box>
          <Stack
            direction={{ xs: "column-reverse", md: "row" }}
            spacing={2}
            sx={{ width: "100%" }}
            pb={4}
            mt={5}
          >
            <Box display="flex" flexDirection="column" gap={2} flex={1}>
              <Typography variant="body1">RECENT CALLS</Typography>
              <Typography variant="body1" color="red">
                APPLICATIONS CLOSED
              </Typography>
              <Typography variant="h4">TecHub Catalyst Program</Typography>
              <Typography variant="body1">
                Hubiquitous is excited to announce that the TecHub Catalyst
                Program is now accepting applications from tech hubs across
                Africa. This innovative program is designed to empower African
                tech hubs to enhance their technological capabilities and become
                leaders in the Internet of Things (IoT) ecosystem.
              </Typography>
              <Box display={"flex"} gap={2}>
                {/* <Link
                  
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    width: "fit-content",
                    backgroundColor: "#FFBA00",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  Apply here
                </Link> */}
                {/* <Button
                  variant="contained"
                  component={Link}
                  to={
                    "https://docs.google.com/forms/d/e/1FAIpQLSeVcod668fT8OXMqye-cZa9kPnqTaor8PNpx8kw3RxkxH7_Cg/viewform"
                  }
                  target="_blank"
                  style={{
                    color: "#FFF",
                    textTransform: "none",
                    // borderColor: "#FFBA00",
                  }}
                >
                  Apply here
                </Button> */}
                <Button
                component={Link}
                to="https://storage.googleapis.com/hubiquitous-cms-bucket/Image/selected_List_b75de42336/selected_List_b75de42336.png"
                target="_blank"
                  variant="outlined"
                  style={{
                    color: "#FFBA00",
                    textTransform: "none",
                    borderColor: "#FFBA00",
                  }}
                >
                  View selected Hubs
                </Button>
              </Box>
            </Box>
            <img
              src={HeroImage}
              alt=""
              style={{ flex: 1, borderRadius: "10px" }}
            />
          </Stack>
          {/* About section */}
          <Box py={4} display="flex" flexDirection="column" gap={2}>
            <Typography variant="h4" fontWeight="bold">
              About the Call
            </Typography>
            <Typography variant="body1">
              The Techub Catalyst Program intends to bring at least 10 African
              Tech Hubs to a higher level in IoT and emerging technologies. The
              program will focus on increasing the hubs’ IoT capabilities as
              well as boosting the development of sustainable value-added
              services. The Hub Catalyst Program will empower the hubs for
              technological innovation, skill development, and entrepreneurial
              growth within their local ecosystems. We will provide intensive
              IoT training to the hub personnel (“Train the trainers”). This
              includes both virtual and in-person training on cutting-edge
              technologies. The in-person training will take place in Tanzania
              for one week. We will also provide the “Solution Box” package to
              the hubs. The Solution Box will be used to set up or upgrade the
              hub’s in-house IoT lab. Using the acquired competencies, the hubs
              will launch a short IoT certification course without their
              community. This is a pre-designed IoT certification program
              including one hackathon. Each hub will also need to develop or
              improve two “Minimum Viable Products” with IoT technologies.
              Finally, we will invite all successful hubs for the “Hubiquitous
              Innovation Week” in Europe. The hubs will have the opportunity to
              present their success stories and demonstrate their MVPs. The
              event will also offer opportunities for partnerships with European
              counterparts in Brussels and Berlin.
            </Typography>
          </Box>
          {/* Benefit and budget section */}
          <Box py={4} display="flex" flexDirection="column" gap={2}>
            <Typography variant="h4" fontWeight="bold">
              Benefits & Budget
            </Typography>
            <Typography variant="body1">
              The hubs joining the program will get the following benefits:
            </Typography>

            {/* Benefit list */}
            <Box display="flex" flexDirection="column" gap={2}>
              {Benefits.map((benefit, index) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems={"center"}
                  gap={1}
                  key={index}
                >
                  <CheckCircleIcon
                    sx={{ color: "#FF6110", fontSize: "14px" }}
                  />
                  <Typography variant="body1">{benefit.text}</Typography>
                </Box>
              ))}
            </Box>
            <Typography variant="body1">
              Additionally, to help the hubs bootstrap their new services, a
              contribution of max 10,000€ will be awarded. This contribution can
              be used for travelling expenses reimbursement, event organisation
              (e.g. hackathon) and MVP costs.
            </Typography>
          </Box>

          {/* Eligibility section */}
          <Box py={4} display="flex" flexDirection="column" gap={2}>
            <Typography variant="h4" fontWeight="bold">
              Eligibility
            </Typography>
            <Typography variant="body1">
              To be considered for the program, TecHubs should:
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              {Eligibilities.map((eligibility, index) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems={"center"}
                  gap={1}
                  key={index}
                >
                  <CheckCircleIcon
                    sx={{ color: "#FF6110", fontSize: "14px" }}
                  />
                  <Typography variant="body1">{eligibility.text}</Typography>
                </Box>
              ))}
            </Box>
            <Typography variant="body1">
              It is important for hubs to carefully review the specific
              eligibility criteria outlined in the call for applications. Hubs
              that fail to fulfill any of the criteria will automatically be
              disqualified.
            </Typography>
          </Box>

          {/* Program timeline section */}
          <Box py={4} display="flex" flexDirection="column" gap={2} mb={10}>
            <Typography variant="h4" fontWeight="bold">
              Hub catalyst program timeline:
            </Typography>
            <TimelineCard />
          </Box>
        </Container>
      </Box>
    </MainLayout>
  );
}

export default Calls;
