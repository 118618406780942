import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import MainLayout from "../components/MainLayout";
import {
  TalentProgramImage1,
  TalentProgramImage2,
  TalentProgramImage3,
  TalentProgramImage4,
  AccProgramImage1,
  AccProgramImage2,
  AccProgramImage3,
  AccProgramImage4,
  CommProgramImage1,
  CommProgramImage2,
  CommProgramImage3,
  CommProgramImage4,
} from "../assets/";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReactGA from "react-ga4";
import { StyledHeader } from "../theme";
import { Link, useLocation } from "react-router-dom";
import CatalystProgramImmage1 from "../assets/catalystProgramImmage1.jpg";
import CatalystProgramImmage2 from "../assets/catalystProgramImmage2.jpg";
import CatalystProgramImmage3 from "../assets/catalystProgramImmage3.jpg";
import CatalystProgramImmage4 from "../assets/catalystProgramImmage4.jpg";

const CatalystProgramImages = [
  {
    id: 1,
    imageUrl: CatalystProgramImmage1,
  },
  {
    id: 2,
    imageUrl: CatalystProgramImmage2,
  },
  {
    id: 3,
    imageUrl: CatalystProgramImmage3,
  },
  {
    id: 4,
    imageUrl: CatalystProgramImmage4,
  },
];
const CatalystProgramBenefits = [
  {
    text: "Intensive IoT training for hub personnel",
  },
  {
    text: "An IoT hardware and software kit to set up or upgrade an IoT lab",
  },
  {
    text: "Funding to help develop new IoT-based services",
  },
  {
    text: "The opportunity to participate in a European innovation week and showcase achievements",
  },
  {
    text: "Potential for partnerships with European counterparts",
  },
];

const TalentProgramImages = [
  {
    id: 1,
    imageUrl: TalentProgramImage1,
  },
  {
    id: 2,
    imageUrl: TalentProgramImage2,
  },
  {
    id: 3,
    imageUrl: TalentProgramImage3,
  },
  {
    id: 4,
    imageUrl: TalentProgramImage4,
  },
];
const AcceleratorProgramImages = [
  {
    id: 1,
    imageUrl: AccProgramImage1,
  },
  {
    id: 2,
    imageUrl: AccProgramImage2,
  },
  {
    id: 3,
    imageUrl: AccProgramImage3,
  },
  {
    id: 4,
    imageUrl: AccProgramImage4,
  },
];
const AcceleratorBenefits = [
  {
    text: "Develop your MVP with hands-on support from experts.",
  },
  {
    text: "Open doors to funding and partnerships.",
  },
  {
    text: "Collaborate with European and African peers for global impact.",
  },
];
const CommunityProgramImages = [
  {
    id: 1,
    imageUrl: CommProgramImage1,
  },
  {
    id: 2,
    imageUrl: CommProgramImage2,
  },
  {
    id: 3,
    imageUrl: CommProgramImage3,
  },
  {
    id: 4,
    imageUrl: CommProgramImage4,
  },
];
const CommunityBenefits = [
  {
    text: "Shared passion",
  },
  {
    text: "Continuous learning",
  },
  {
    text: "Impactful collaboration",
  },
];
function HubiquitousPrograms() {
  const location = useLocation();
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Programs page",
    });
  }, []);

  
  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Box
            textAlign={"center"}
            pb={4}
            mt={5}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Typography variant="body1" color={"#505050"}>
              THE PROJECT
            </Typography>
            <StyledHeader darkText="" coloredText="Hubiquitous Programs" />
            <Typography variant="body1" color={"#505050"}>
              The European Commission is aimed at empowering innovation and
              sustainable technological solutions in <br /> Africa through the
              creation of a joint Africa-Europe Startup & Innovation Ecosystem{" "}
              <br /> for long-term collaborations and partnerships between these
              continents.
            </Typography>
          </Box>
        </Container>
        <Box id="tecHub-catalyst-program" />
        <Box bgcolor={"#F6F2E4"}>
          <Container>
            <Grid container py={4}>
              <Grid
                item
                xs={12}
                // md={10}
                display="flex"
                flexDirection="column"
                gap={2}
                mb={4}
              >
                {/* <Typography variant="body1" color={"#505050"}>
                  THE HUBIQUITOUS PROGRAMS
                </Typography> */}
                <Typography variant="h5" fontWeight={"bold"} color={" #2D2D2D"}>
                  TecHub Catalyst Program
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={"bold"}
                  fontSize={20}
                  color={"#505050"}
                >
                  Empowering African Innovation in IoT
                </Typography>
                <Typography variant="body1" color={"#505050"}>
                  Hubiquitous TecHub Catalyst Program is a program designed to
                  empower African Tech Hubs in the field of IoT and emerging
                  technologies. The program offers training, resources, and
                  funding to help hubs improve their capabilities, develop new
                  services, and connect with European partners.
                </Typography>
                {/* Benefit list */}
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography variant="body1" color={"#505050"}>
                    Here are the key benefits of the program:
                  </Typography>
                  {CatalystProgramBenefits.map((benefit, index) => (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems={"center"}
                      gap={1}
                      key={index}
                    >
                      <CheckCircleIcon
                        sx={{ color: "#FF6110", fontSize: "20px" }}
                      />
                      <Typography variant="body1" color={"#505050"}>
                        {benefit.text}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              {/* Images */}
              <Grid item container xs={12} spacing={5}>
                {CatalystProgramImages.map((item, index) => (
                  <Grid item xs={6} sm={4} md={3} key={item.id}>
                    <img
                      src={item.imageUrl}
                      alt={`TecHub catalyst program ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "179px",
                        // flexShrink: 0,
                        borderRadius: "10px",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Talent program section */}
        <Container>
          <Box id="talent-program" />
          <Grid container py={4}>
            <Grid
              item
              xs={12}
              // md={10}
              display="flex"
              flexDirection="column"
              gap={2}
              mb={4}
            >
              {/* <Typography variant="body1" color={"#505050"}>
                THE HUBIQUITOUS PROGRAMS
              </Typography> */}
              <Typography variant="h5" fontWeight={"bold"} color={" #2D2D2D"}>
                Talent Program
              </Typography>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                fontSize={20}
                color={"#505050"}
              >
                Level Up Your Technical Skills!
              </Typography>
              <Typography variant="body1" color={"#505050"}>
                HUBiquitous' Talent Program empowers youth and marginalized
                groups (17-35 yrs old) with hands-on training in disruptive tech
                like IoT, AI, and Big Data. Boost your skills, build innovative
                solutions, and land your dream job. Join the future of African
                tech!
              </Typography>
            </Grid>
            {/* Images */}
            <Grid item container xs={12} spacing={5}>
              {TalentProgramImages.map((item, index) => (
                <Grid item xs={6} sm={4} md={3} key={item.id}>
                  <img
                    src={item.imageUrl}
                    alt={`Talent program ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "179px",
                      // flexShrink: 0,
                      borderRadius: "10px",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>

        {/* Accelerator program section */}
        <Box id="accelerator-program" />
        <Box bgcolor={"#F6F2E4"}>
          <Container>
            <Grid container py={4}>
              <Grid
                item
                xs={12}
                // md={10}
                display="flex"
                flexDirection="column"
                gap={2}
                mb={4}
              >
                {/* <Typography variant="body1" color={"#505050"}>
                  THE HUBIQUITOUS PROGRAMS
                </Typography> */}
                <Typography variant="h5" fontWeight={"bold"} color={" #2D2D2D"}>
                  Accelerator Program
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={"bold"}
                  fontSize={20}
                  color={"#505050"}
                >
                  From idea to impact: Power your African startup with
                  HUBiquitous
                </Typography>
                <Typography variant="body1" color={"#505050"}>
                  HUBiquitous' Accelerator Program will foster creation of new
                  application solutions by providing the technical and business
                  support for successful entrepreneurs and startups. The
                  Accelerator Program will support a total of least 50
                  innovative application solutions through two open calls during
                  the course of the project.
                </Typography>
                {/* Benefit list */}
                <Box display="flex" flexDirection="column" gap={1}>
                  {AcceleratorBenefits.map((benefit, index) => (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems={"center"}
                      gap={1}
                      key={index}
                    >
                      <CheckCircleIcon
                        sx={{ color: "#FF6110", fontSize: "20px" }}
                      />
                      <Typography variant="body1" color={"#505050"}>
                        {benefit.text}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              {/* Images */}
              <Grid item container xs={12} spacing={5}>
                {AcceleratorProgramImages.map((item, index) => (
                  <Grid item xs={6} sm={4} md={3} key={item.id}>
                    <img
                      src={item.imageUrl}
                      alt={`Accelerator program ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "179px",
                        // flexShrink: 0,
                        borderRadius: "10px",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Container>
        </Box>
        {/* Community program section */}
        <Box id="community-program" />
        <Container>
          <Grid container py={4} mb={10}>
            <Grid
              item
              xs={12}
              // md={12}
              display="flex"
              flexDirection="column"
              gap={2}
              mb={4}
            >
              {/* <Typography variant="body1" color={"#505050"}>
                THE HUBIQUITOUS PROGRAMS
              </Typography> */}
              <Typography variant="h5" fontWeight={"bold"} color={" #2D2D2D"}>
                Community Program
              </Typography>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                fontSize={20}
                color={"#505050"}
              >
                Building a community!
              </Typography>
              <Typography variant="body1" color={"#505050"}>
                HUBiquitous goes beyond projects, building a thriving
                Africa-Europe innovation ecosystem where diverse voices connect,
                collaborate, and conquer. Join DIHs, startups, and more in a
                community fueled by:
              </Typography>
              {/* Benefit list */}
              <Box display="flex" flexDirection="column" gap={1}>
                {CommunityBenefits.map((benefit, index) => (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems={"center"}
                    gap={1}
                    key={index}
                  >
                    <CheckCircleIcon
                      sx={{ color: "#FF6110", fontSize: "20px" }}
                    />
                    <Typography variant="body1" color={"#505050"}>
                      {benefit.text}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            {/* Images */}
            <Grid item container xs={12} spacing={5}>
              {CommunityProgramImages.map((item, index) => (
                <Grid item xs={6} sm={4} md={3} key={item.id}>
                  <img
                    src={item.imageUrl}
                    alt={`Community program  ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "179px",
                      // flexShrink: 0,
                      borderRadius: "10px",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </MainLayout>
  );
}

export default HubiquitousPrograms;
