import * as React from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  List,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";
import { EuLogo } from "../assets/";
import CallIcon from "@mui/icons-material/Call";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import navItems from "../utils/navItems";
import MobileNavItems from "./MobileNavItem";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
function Footer() {
  return (
    <Box
      sx={{
        display: "block",
        // backgroundColor: "floralwhite",
        paddingY: "2rem",
        pt: 8,
      }}
    >
      <Container
        maxWidth="lg"
        // sx={{
        //   pt: "1rem",
        // }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Grid item container spacing={1} alignItems="center">
              <Grid item sm={4}>
                <img src={Logo} style={{ width: "100%" }} alt="" />
              </Grid>
              <Grid item sm={4}>
                <img src={EuLogo} style={{ width: "50%" }} alt="EU" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <List>
              {navItems.map((item: any, index: number) => (
                <MobileNavItems item={item} key={index} />
              ))}
            </List>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div>
              <Typography style={{ fontWeight: "bold" }} gutterBottom>
                Connect with us
              </Typography>
              <Box display={"flex"} gap={1}>
                <Link to="https://twitter.com/Hubiquitous2021" target="_blank">
                  <XIcon style={{ color: "#2d2d2d" }} />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/hubiquitous-h2020?trk=blended-typeahead"
                  target="_blank"
                >
                  <LinkedInIcon style={{ color: "#2d2d2d" }} />
                </Link>
              </Box>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              Contact
            </Typography>

            <Box display={"flex"} flexDirection="column" gap={2}>
              <Box display={"flex"} gap={1}>
                <LocationOnOutlinedIcon
                  style={{ marginTop: "5px" }}
                  fontSize="small"
                />
                <div>
                  <Typography>Delitzscher Str. 36 D-04129 Leipzig</Typography>
                  <Typography>Deutschland/Germany</Typography>
                </div>
              </Box>

              <Box display={"flex"} gap={1}>
                <EmailOutlinedIcon
                  style={{ marginTop: "5px" }}
                  fontSize="small"
                />
                <Typography>corentin.dupont@innotec21.de</Typography>
              </Box>
              {/* <Box display={"flex"} gap={1}>
                <CallIcon style={{ marginTop: "5px" }} fontSize="small" />
                <Typography>+49 341-265 882-74</Typography>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
