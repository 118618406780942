import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useRef } from "react";
import MainLayout from "../components/MainLayout";
import ReactGA from "react-ga4";
import ReactPlayer from "react-player/lazy";
import Marquee from "react-fast-marquee";

import {
  solutionsLab,
  appBusinessBox,
  EnablersPageHeroImage,
  MeethubPlatform,
  HiremeSvg,
  TouchpadSvg,
  CreativitySvg,
  FindUserMaleSvg,
  PresentationScreenSvg,
  DevelopmentSkillSvg,
  ProcessorSvg,
  Wazidev,
  Wazigate,
} from "../assets/";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { StyledHeader } from "../theme";
import SolutionsLab from "../assets/solutionsLab.png";
import WaziLab from "../assets/solutionsLab.webp";
import WaziSenseBoard from "../assets/waziSenseBoard.jpg";
import AppBox from "../assets/applicationBox.svg";
import MeethubPlatform2 from "../assets/meethubPlatform2.png";
// import { HashLink, NavHashLink } from "react-router-hash-link";

const appBoxAndmeethubDetails = [
  {
    id: 1,
    containerRef: "app-business-box",
    title: "Application Business Box",
    subTilte: "3 ESSENTIAL SUPPORT ELEMENTS",
    description:
      "The Application Business Box is a “ready to use” package of technical, training and business support to entrepreneurs and startups. The box is constituted of three essential elements (i.e. hardware & software kits, training and coaching supports, as well as business development tools and templates) needed by entrepreneurs and startup for rapid prototyping and creation of new businesses.",
    image: AppBox,

    details: [
      {
        id: 1,
        title: "Technical kits",
        description:
          "Application domain-specific integrated hardware and software    prototyping kit and dedicated APIs for emerging technologies such as IoT, AI and Big Data. The kits will maximize the usage of open-source resources. These technical kits will include, for instance, specific hardware development boards with on-board microcontrollers, electronic components & sensors, and radio communication modules.",
        icon: ProcessorSvg,
      },
      {
        id: 2,
        title: "Training & capacity building",
        description:
          "Supporting training and online courses for the technical as well as entrepreneurial skills development to make use of the Application Business Boxes.",
        icon: DevelopmentSkillSvg,
      },
      {
        id: 3,
        title: "Business templates",
        description:
          "Business templates to develop business plans designed through dedicated design thinking events. These templates will leverage on established tools, like the well-known Business Model Canvas and other identified valuable online resources and guidelines, to develop convincing Value Propositions.",
        icon: PresentationScreenSvg,
      },
    ],
  },
  {
    id: 2,
    containerRef: "meethub-platform",
    title: "MeetHub Platform",
    subTilte: "VALUABLE FEATURES",
    description:
      "HUBiquitous’ MeetHub is an online collaboration platform to boost Africa-Europe innovation partnerships as well as the creation of a joint DIHs and startup innovation ecosystem. The MeetHub enables technical & business networking and match-making among ecosystem actors, and the development of collaborations. It is also an active knowledge repository base through which DIHs will have access to online training, best practices, etc.",
    image: MeethubPlatform2,
    link: "https://meethub.cloudport.tech/",

    details: [
      {
        id: 1,
        title: "Partners Search",
        description:
          " To search profiles of African as well as European ecosystem actors (e.g. DIHs, entrepreneurs, trainers, start-ups, incubators, SMEs, etc.), including Diaspora communities.",
        icon: FindUserMaleSvg,
      },
      {
        id: 2,
        title: "Talents Search",
        description:
          "To offer the opportunity to search for African young talents, skilled trainers and experienced professionals for joint project collaboration. To screen the CVs of the best African talents for enabling employment opportunity.",
        icon: HiremeSvg,
      },
      {
        id: 3,
        title: "Promote innovation, knowledge and opportunity",
        description:
          "To offer the opportunity for DIHs to promote the innovation services based on the Innovation Enablers to the ecosystem actors. MeetHub enables to share Business Intelligence, such as business practices, salient levers and barriers in Africawith a dedicated focus on partners’ countries to efficiently support European start-ups and entrepreneurs when developing a partnership with African stakeholders.",
        icon: CreativitySvg,
      },
      {
        id: 3,
        title: "Access HUBiquitous digital resources",
        description:
          "Such as online courses & materials, user manuals, guidelines and design thinking, entrepreneurial & coaching resources.",
        icon: TouchpadSvg,
      },
    ],
  },
];

const Programs = [
  {
    caption: "WaziDev",
    img: Wazidev,
    title: "WaziDev",
    text: "WaziDev is a development board with an embedded LoRa module that allows you to simply develop IoT sensor nodes as well as IoT actuator nodes. It is an ideal solution for startups and entrepreneurs who want to rapidly prototype their IoT    applications.",
    link: "https://lab.waziup.io/resources/waziup/wazidev#overview",
  },
  {
    caption: "WaziGate",
    img: Wazigate,
    title: "WaziGate",
    text: "WaziGate is a powerful edge computing platform that enables you to run your applications locally even without an internet connection. It is the ideal IoT LoRa Gateway for your remote IoT applications. WaziGate can cover up to 100 IoT end devices using the LoRa radio network and can also offer the ability for actuation. WaziGate is an affordable solution for emerging countries’ needs.",
    link: "https://lab.waziup.io/resources/waziup/wazigate#overview",
  },
  {
    caption: "WaziLab",
    img: WaziLab,
    title: "WaziLab",
    text: "The WaziLab is a platform for learning and teaching loT through live prototyping. It simplifies the prototyping process for startups through better hardware accessibility, guided prototyping, loT capacity building and initial business support for startups.",
    link: "https://www.waziup.org/capacity-building/#wazilab",
  },
  {
    caption: "WaziSense Board",
    img: WaziSenseBoard,
    title: "WaziSense Board",
    text: "The Wazisense board is designed for rapid MVP prototyping, featuring solar panel input, a lithium battery attachment section, and multiple sensor attachment points.",
    link: "#",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F6F2E4",
  padding: theme.spacing(1),
  borderRadius: "16px",
  border: "1px solid #E7E2D2",
  boxShadow: "none",
  height: "100%",
}));
function InnovationEnablers() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Innovation Enablers page",
    });
  }, []);
  const currentUrl = location.hash.split("#")[1];

  const SolutionsLabSectionRef = useRef<null | HTMLElement>(null);
  const AppBusinessBoxSectionRef = useRef<null | HTMLElement>(null);
  const meethubPlatformSectionRef = useRef<null | HTMLElement>(null);
  const refs = {
    AppBusinessBoxSectionRef: AppBusinessBoxSectionRef,
    meethubPlatformSectionRef: meethubPlatformSectionRef,
  };

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Box
            textAlign={"center"}
            pb={4}
            mt={5}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Typography variant="body2" color={"#505050"}>
              THE PROJECT
            </Typography>
            <StyledHeader darkText="The" coloredText="Innovation Enablers" />
          </Box>
          <Box id="solution-box" />
          <Grid
            container
            display={"flex"}
            flexDirection={{ xs: "column-reverse", md: "row" }}
            alignItems={"center"}
            justifyContent="space-between"
            gap={3}
            pb={4}
            mt={5}
          >
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column" gap={2} flex={1}>
                <Typography
                  variant="body1"
                  color={"#505050"}
                  // ref={SolutionsLabSectionRef}
                >
                  DEVELOPMENTAL & TESTING INFRASTRUCTURE
                </Typography>
                <Typography variant="h4" fontWeight="bold">
                  The Solution Box
                </Typography>
                <Typography variant="body1" color={"#505050"}>
                  The Solution Box is a developmental & testing infrastructure
                  composed of generic hardware/software, networking and
                  computing resources, APIs, tools/platforms and supporting
                  learning material, which can be used to develop, experiment
                  and innovate disruptive IoT, Big Data and AI technologies. The
                  Solution Box will be hosted by the African DIHs.
                </Typography>

                <Button
                  variant="contained"
                  component={Link}
                  target="_blank"
                  to={"https://www.waziup.org/capacity-building/"}
                  sx={{
                    color: "white",
                    width: "fit-content",
                    borderRadius: "10px",
                  }}
                >
                  Visit website
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <img
                src={SolutionsLab}
                alt=""
                style={{
                  width: "100%",
                  height: "317px",

                  // flexShrink: 0,
                  borderRadius: "10px",
                }}
              />
            </Grid>
          </Grid>

          {/* <Grid container spacing={5} sx={{ pb: "3rem" }}>
            {Programs.map((program, index) => (
              <Grid item sm={12} md={6} lg={4} key={index}>
                <Link
                  to={program.link}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <Item
                    sx={{ cursor: "pointer" }}
                    // onClick={() => navigate(program.link)}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        backgroundImage: `url(${program.img})`,
                        backgroundPosition: "center",
                        backgroundSize: "auto 100%",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "rgba(50, 50, 50, 0.5)",
                        backgroundBlendMode: "multiply",

                        color: "#FFF",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        py="5rem"
                        sx={{
                          // pt: "12px",
                          fontWeight: "bold",
                          textAlign: "center",
                          color: "#FFF",
                        }}
                        gutterBottom
                      >
                        {program.caption}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="body2"
                        gutterBottom
                        sx={{ pb: "10px", lineHeight: "149%" }}
                      >
                        {program.text}
                      </Typography>
                    </Box>
                  </Item>
                </Link>
              </Grid>
            ))}
          </Grid> */}
        </Container>

        <Marquee pauseOnHover>
          <Box display="flex" gap="1.5rem" mb={4}>
            {Programs.map((program, index) => (
              <Box
                key={index}
                width="320px"
                sx={{
                  marginRight: Programs.length - 1 === index ? "5rem" : "",
                }}
              >
                <Link
                  target="_blank"
                  to={program.link}
                  style={{ textDecoration: "none" }}
                >
                  <Item
                    sx={{ cursor: "pointer", textDecoration: "none" }}
                    // onClick={() => navigate(program.link)}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        backgroundImage: `url(${program.img})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "rgba(50, 50, 50, 0.5)",
                        backgroundBlendMode: "multiply",
                        textDecoration: "none",
                        color: "#FFF",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        py="5rem"
                        sx={{
                          // pt: "12px",
                          fontWeight: "bold",
                          textAlign: "center",
                          color: "#FFF",
                        }}
                        gutterBottom
                      >
                        {program.caption}
                      </Typography>
                    </Box>
                    <Box>
                      {/* <Typography
                      variant="body1"
                      sx={{ pt: "12px", fontWeight: "bold" }}
                      gutterBottom
                    >
                      {program.title}
                    </Typography> */}
                      <Typography
                        variant="body2"
                        gutterBottom
                        sx={{ pb: "10px", lineHeight: "149%" }}
                      >
                        {program.text}
                      </Typography>
                    </Box>
                  </Item>
                </Link>
              </Box>
            ))}
          </Box>
        </Marquee>

        {appBoxAndmeethubDetails.map((appBoxAndMeethubDetail, index) => (
          <Box key={index}>
            <Box
              ref={
                appBoxAndMeethubDetail.containerRef ===
                "AppBusinessBoxSectionRef"
                  ? AppBusinessBoxSectionRef
                  : meethubPlatformSectionRef
              }
            ></Box>

            <Container maxWidth="lg">
              <Grid
                container
                display={"flex"}
                flexDirection={{ xs: "column-reverse", md: "row" }}
                alignItems={"center"}
                justifyContent="space-between"
                gap={3}
                pb={4}
                mt={5}
              >
                <Grid item xs={12} md={6}>
                  <Box display="flex" flexDirection="column" gap={2} flex={1}>
                    <Typography variant="body1" color="#505050">
                      {appBoxAndMeethubDetail?.subTilte}
                    </Typography>
                    <Typography variant="h4" fontWeight="bold">
                      {appBoxAndMeethubDetail?.title}
                    </Typography>

                    <Typography variant="body1" color="#505050" align="justify">
                      {appBoxAndMeethubDetail?.description}
                    </Typography>
                    {appBoxAndMeethubDetail?.link && (
                      <Button
                        variant="contained"
                        component={Link}
                        to={appBoxAndMeethubDetail.link}
                        target="_blank"
                        sx={{
                          color: "white",
                          width: "fit-content",
                          borderRadius: "10px",
                        }}
                      >
                        Visit website
                      </Button>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <img
                    src={appBoxAndMeethubDetail.image}
                    style={{
                      width: "100%",
                      height: "217px",
                      // flexShrink: 0,
                      borderRadius: "10px",
                    }}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Container>

            <Box sx={{ background: "#F6F2E4", mt: 4, mb: 10 }}>
              <Container>
                <Grid container spacing={2} sx={{ pb: 4 }}>
                  {appBoxAndMeethubDetail.details?.map((detail, index) => (
                    <Grid
                      item
                      xs={6}
                      md={appBoxAndMeethubDetail.details.length === 3 ? 4 : 3}
                      key={index}
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <img
                        src={detail.icon}
                        alt=""
                        style={{ width: "70px", height: "70px" }}
                      />
                      <Typography variant="body2" fontWeight="bold">
                        {detail.title}
                      </Typography>
                      <Typography variant="body2" align="justify">
                        {detail.description}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Box>
          </Box>
        ))}
      </Box>
    </MainLayout>
  );
}

export default InnovationEnablers;
