import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import MainLayout from "../components/MainLayout";
import { LinkedInSVG, TwitterSVG } from "../assets/ImagesExport";
import { Link, useParams } from "react-router-dom";
import useFetch from "../components/useFetch";
import ReactGA from "react-ga4";
import defaultBlogImage from "../assets/hubiquitousBlog.png";
export default function BlogDetails() {
  let { id } = useParams();

  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: `Blog post ${id} page`,
    });
  }, []);
  const { data, isLoading, isError } = useFetch(
    `/blog-posts/${id?.split("-")[0]}?populate[0]=image`
  );

  if (isLoading) {
    return <Typography variant="body1">Loading...</Typography>;
  }
  if (isError) {
    return (
      <Typography variant="body1" color="error">
        Error fetching blog posts
      </Typography>
    );
  }
  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Box width={{ xs: "100%", md: "80%" }} m="auto" mb={15}>
            <Box
              textAlign={"center"}
              pb={4}
              mt={5}
              display={"flex"}
              flexDirection={"column"}
              gap={2}
            >
              <Typography variant="body2" color={"#505050"}>
                {new Date(data?.data?.attributes?.publishedAt).toDateString()}
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                {data?.data?.attributes?.title}
              </Typography>
              {/* <Link to="https://hubiquitous.eu/" target="_blank"> */}
                <Typography variant="body2" color={"#505050"}>
                  Blog by HUBiquitous
                </Typography>
              {/* </Link> */}
              <Box display="flex" justifyContent={"center"} gap={2}>
                <Link to="https://twitter.com/Hubiquitous2021" target="_blank">
                  <img src={TwitterSVG} alt="" height={25} width={25} />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/hubiquitous-h2020?trk=blended-typeahead"
                  target="_blank"
                >
                  <img src={LinkedInSVG} alt="" height={25} width={25} />
                </Link>
              </Box>
            </Box>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "center", mb: 3 }}
            >
              <Grid
                item
                xs={12}
                // md={10}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  position: "relative",
                  background: "#D9D9D9",
                  borderRadius: "12px",
                  overflow: "hidden",
                  width: "100%",                  
                  // height: 255,
                }}
              >
                <img
                  src={
                    data?.data?.attributes?.image?.data?.attributes?.url ||
                    defaultBlogImage
                  }
                  alt="blog"
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    aspectRatio: "16/9",
                    objectFit: "cover",
                  }}
                />
              </Grid>
            </Grid>
            <Box>
              {data?.data?.attributes?.content.map(
                (item: any, index: number) => {
                  if (item?.type === "paragraph")
                    return (
                      <p key={index}>
                        {item?.children.map((child: any, idx: number) => {
                          if (child.type === "link")
                            return (
                              <a
                                href={child?.url}
                                key={idx}
                                style={{ color: "#FFBA00" }}
                              >
                                {child?.children.map((link: any) => link?.text)}
                              </a>
                            );
                          return (
                            <span
                            key={idx}
                              style={{
                                fontWeight: child.bold ? "bold" : "normal",
                              }}
                            >
                              {child?.text}
                            </span>
                          );
                        })}
                      </p>
                    );
                  return "";
                }
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </MainLayout>
  );
}
