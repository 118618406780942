import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import MainLayout from "../components/MainLayout";
import ReactGA from "react-ga4";
export default function ContactUs() {
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Contact us page",
    });
  }, []);
  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Box width={{ xs: "100%", md: "50%" }} m="auto" mb={15}>
            <Box
              textAlign={"center"}
              pb={4}
              mt={5}
              display={"flex"}
              flexDirection={"column"}
              gap={2}
            >
              <Typography variant="body2" color={"#505050"}>
                CONTACT US
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                Get in touch
              </Typography>

              <Typography variant="body2" color={"#505050"}>
                Do you have any questions, remarks or just want to say hi? Do
                not hesitate to contact us and we will get back to you.
              </Typography>
            </Box>
            <Grid container>
              <Grid
                item
                xs={12}
                md={5}
                pt={4}
                borderRight={{ xs: "none", md: "2px solid #FFBA00" }}
              >
                <Typography variant="body1" gutterBottom>
                  PROJECT COORDINATOR
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  Corentin Dupont
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                // pl={{ xs: 0, md: 7 }}
                p={4}
                pr={1}
                pl={7}
                sx={{ backgroundColor: "#FAF5E1" }}
              >
                <Typography variant="body1" gutterBottom>
                  CONTACT INFO
                </Typography>
                <Box mb={2}>
                  <Typography variant="body1" gutterBottom fontWeight="bold">
                    Email Address
                  </Typography>
                  <Typography
                    variant="body1"
                    color="primary"
                    gutterBottom
                    fontWeight="bold"
                  >
                    corentin.dupont@innotec21.de
                  </Typography>
                </Box>
                {/* <Box mb={2}>
                  <Typography variant="body1" gutterBottom fontWeight="bold">
                    Phone Number
                  </Typography>
                  <Typography
                    variant="body1"
                    color={"primary"}
                    fontWeight="bold"
                    gutterBottom
                  >
                    +49 341-265 882-74
                  </Typography>{" "}
                </Box> */}
                <Typography variant="body1" fontWeight="bold" gutterBottom>
                  INNOTEC21 GmBH
                </Typography>
                <Typography variant="body1">
                  Delitzscher Str. 36 D-04129 Leipzig
                </Typography>
                <Typography variant="body1">Deutschland/Germany</Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </MainLayout>
  );
}
