const navItems = [
  {
    name: "About Us",
    childrens: [
      // { path: "hubiquitous-project", name: "Hubiquitous Projects" },
      { path: "consortium", name: "Consortium" },
      // { path: "our-experts", name: "Meet Our Experts" },
    ],
  },
  {
    name: "The Project",
    childrens: [
      { path: "innovation-enablers", name: "Innovation Enablers" },
      { path: "hubiquitous-programs", name: "Programs" },
      // { path: "technologies", name: "Technologies" },
    ],
  },
  {
    name: "Our Programs",
    childrens: [
      { path: "techub-catalyst-program", name: "TecHub Catalyst Program" },
      { path: "talent-program", name: "2nd Talent program" },
      { path: "accelerator-program", name: "1st Accelerator program" },
    ],
  },
  // { path: "calls", name: "Calls" },
  
  // { path: "blog", name: "Blog" },
  {
    name: "Media",
    childrens: [
      { path: "blog", name: "Blog" },
      { path: "videos", name: "Videos" },
      { path: "gallery", name: "Gallery" },
      { path: "downloads", name: "Downloads" },
    ],
  },
  { path: "contact-us", name: "Contact Us" },
];

 export default navItems;