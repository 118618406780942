import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home";
import TechHubCatalyst from "./pages/TecHubCatalyst";
import OurExperts from "./pages/OurExperts";
import OurConsortium from "./pages/OurConsortium";
import HubiquitousPrograms from "./pages/HubiquitousPrograms";
import InnovationEnablers from "./pages/InnovationEnablers";
import WazihubTechnologies from "./pages/WazihubTechnologies";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import ContactUs from "./pages/ContactUs";
import TalentProgram from "./pages/TalentProgram";
import AcceleratorProgram from "./pages/AcceleratorProgram";
import Videos from "./pages/Videos";
import Gallery from "./pages/Gallery";
import Downloads from "./pages/Downloads";
import NotFound from './NotFound'; // Import the 404 component

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<HomePage />} />
        <Route path="/techub-catalyst-program" element={<TechHubCatalyst />} />
        <Route path="/talent-program" element={<TalentProgram />} />
        <Route path="/accelerator-program" element={<AcceleratorProgram />} />
        {/* <Route path="/calls" element={<Calls />} /> */}
        <Route path="/our-experts" element={<OurExperts />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/consortium" element={<OurConsortium />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/hubiquitous-programs" element={<HubiquitousPrograms />} />
        <Route path="/innovation-enablers" element={<InnovationEnablers />} />
        <Route path="/technologies" element={<WazihubTechnologies />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
